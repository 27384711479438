export const ADD_TO_CART = "ADD_TO_CART";

export const REMOVE_CART_ITEM = "REMOVE_CART_ITEM";

export const GET_CART_ITEM = "GET_CART_ITEM";

export const SAVE_SHIPPING_INFO = "SAVE_SHIPPING_INFO";

export const ADD_TO_WISHLIST = "ADD_TO_WISHLIST";

export const REMOVE_WISHLIST_ITEM = "REMOVE_WISHLIST_ITEM";
