import React, { Fragment, useEffect, useState } from "react";
import { Formik, Form } from "formik"
import * as Yup from "yup"
import "./newProduct.css";
import { useSelector, useDispatch } from "react-redux";
// import { clearErrors, createProduct } from "../../actions/productAction";
import { useAlert } from "react-alert";

import FormikController from "../../reusable-component/formik/FormikController";
import MetaData from "../layout/MetaData";
import SideBar from "./Sidebar";
// import { NEW_PRODUCT_RESET } from "../../constants/productConstants";
// import { axiosInstance } from "../../Utilites/Axios";
// import API_URL from "../../config";
import { useNavigate, useParams } from "react-router";
import { addNewProduct, clearProductDetails, getProductDetailById, updateProduct } from "../../redux-toolkit/slices/adminSlice/adminProductSlice";
import { toast } from "react-toastify";
const MAX_COUNT = 10;



const NewProduct = ({ history }) => {
  // console.log("history", history)

  const dispatch = useDispatch();
  const alert = useAlert();
  const params = useParams()
  const navigate = useNavigate()

  const { aProducts, userAuth } = useSelector((state) => state);

  const role = userAuth?.userLoginData?.role
  if (role !== "admin") {
    navigate("/")
  }
  const { productDetail } = aProducts
  const { product } = productDetail

  // const [name, setName] = useState("");
  // const [price, setPrice] = useState(0);
  // const [description, setDescription] = useState("");
  // const [category, setCategory] = useState("");
  // const [Stock, setStock] = useState(0);
  // const [images, setImages] = useState([]);
  // const [imagesPreview, setImagesPreview] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([])
  const [savedImagePreview, setSavedImagePreview] = useState([])
  const [isEdit, setIsEdit] = useState(false)
  const [fileLimit, setFileLimit] = useState(false);




  const intialState = {
    name: product?.name ?? "",
    description: product?.description ?? "",
    instruction: product?.instruction ?? "",
    sku: product?.sku ?? "",
    price: product?.price ?? "",
    Stock: product?.Stock ?? "",
    category: product?.category?.toString() ?? "",
    size: product?.size?.toString() ?? "",
    numOfReviews: product?.numOfReviews ?? ""
  }


  const validationSchema = Yup.object({
    name: Yup.string().required("Required"),
    description: Yup.string().required("Required"),
    instruction: Yup.string().required("Required"),
    sku: Yup.string().required("Required"),
    price: Yup.string().required("Required"),
    Stock: Yup.string().required("Required"),
    category: Yup.string().required("Required"),
    size: Yup.string().required("Required"),
    numOfReviews: Yup.string().required("Required"),
  });




  const handleUploadFiles = files => {
    const uploaded = [...uploadedFiles];
    let limitExceeded = false;
    files.some((file) => {
      if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        uploaded.push({ file, preview: URL.createObjectURL(file) });
        if (uploaded.length === MAX_COUNT) setFileLimit(true);
        if (uploaded.length > MAX_COUNT) {
          alert(`You can only add a maximum of ${MAX_COUNT} files`);
          setFileLimit(false);
          limitExceeded = true;
          return true;
        }
      }
    });
    if (!limitExceeded) setUploadedFiles(uploaded);
  };

  const handleFileEvent = (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    handleUploadFiles(chosenFiles);
  };

  const removeFile = (fileName) => {
    const updatedFiles = uploadedFiles.filter(file => file.file.name !== fileName);
    setUploadedFiles(updatedFiles);
    if (updatedFiles.length < MAX_COUNT) setFileLimit(false);
  };


  const sizeOption = [
    { key: "", value: "Select" },
    { key: "default", value: "Default" },
    { key: "S", value: "S" },
    { key: "M", value: "M" },
    { key: "L", value: "L" },
    { key: "XL", value: "XL" },
    { key: "XLL", value: "XLL" },
  ]

  const prpductCategoryOption = [
    { key: "", value: "Select" },
    { key: "mouse pad", value: "mouse pad" },
    { key: "photo Caricature", value: "photo Caricature" },
    { key: "sipper", value: "sipper" },
    { key: "mug", value: "mug" },
    { key: "wall clock", value: "wall clock" },
    { key: "tshirt", value: "tshirt" },
    { key: "mask", value: "mask" }
  ]


  const submitHandler = (values) => {
    // console.log("uploadedFiles", uploadedFiles)
    // return false
    const formData = new FormData();
    formData.append('Stock', values.Stock);
    formData.append('category', values.category)
    formData.append('description', values.description)
    formData.append('instruction', values.instruction)
    formData.append('name', values.name)
    formData.append('numOfReviews', values.numOfReviews)
    formData.append('price', values.price)
    formData.append('sku', values.sku)
    formData.append('size', values.size)
    formData.append('isEdit', isEdit)

    if (isEdit) {
      formData.append('imageUpdate', JSON.stringify(savedImagePreview))
    }

    uploadedFiles.forEach(({ file }) => {
      formData.append('files', file);
    });



    if (isEdit) {
      dispatch(updateProduct({ id: params.id, data: formData })).then(resp => {
        toast.success("Product updated successfully")
        navigate("/admin/products")
      }).catch(err => {
        toast.error("Product not updated")
      })
    } else {
      dispatch(addNewProduct(formData)).then(resp => {
        toast.success("Product added successfully")
        navigate("/admin/products")
      }).catch(err => {
        toast.error("Product not added")
      })
    }

  };



  //  edit product
  useEffect(() => {
    if (params.id) {
      dispatch(getProductDetailById(params.id))
      setIsEdit(true)
    }

    return () => {
      dispatch(clearProductDetails())
      setIsEdit(false)
    }
  }, [params, dispatch])


  useEffect(() => {
    product?.images && setSavedImagePreview(product?.images)
  }, [product])





  const removeFileFromServer = (data) => {
    // console.log("data", data)
    const imageFilter = savedImagePreview?.filter(item => item._id !== data._id)
    setSavedImagePreview(imageFilter)

  }

  // console.log("savedImagePreview", savedImagePreview)

  return (
    <Fragment>
      <MetaData title="Create Product" />
      <div className="dashboard">
        <SideBar />
        <div className="newProductContainer">

          <Formik
            initialValues={intialState}
            validationSchema={validationSchema}
            onSubmit={submitHandler}
            enableReinitialize={true}
          >
            {(formik) => (
              <Form className="form">
                <div className="form-input">
                  <FormikController
                    control="input"
                    className="input-control"
                    type="text"
                    label="Product Name"
                    name="name"
                  />
                </div>

                <div className="form-input">
                  <FormikController
                    control="textArea"
                    label="Product Description"
                    name="description"
                    className="input-control"
                  />
                </div>

                <div className="form-input">
                  <FormikController
                    control="textArea"
                    label="Product Instruction"
                    name="instruction"
                    className="input-control"
                  />
                </div>

                <div className="form-input">
                  <FormikController
                    control="input"
                    type="text"
                    label="Product SKU"
                    name="sku"
                    className="input-control"
                  />
                </div>

                <div className="form-input">
                  <FormikController
                    control="input"
                    label="Product Price"
                    name="price"
                    className="input-control"
                  />
                </div>


                <div className="form-input">
                  <FormikController
                    control="input"
                    label="Product Stock"
                    name="Stock"
                    className="input-control"
                  />
                </div>

                <div className="form-input">
                  <FormikController
                    control="select"
                    label="Product Size"
                    name="size"
                    options={sizeOption}
                    className="input-control"
                  />
                </div>


                <div className="form-input">
                  <FormikController
                    control="select"
                    label="Product Category"
                    name="category"
                    options={prpductCategoryOption}
                    className="input-control"
                  />
                </div>


                <div className="form-input">
                  <FormikController
                    control="input"
                    label="Number of Reviews"
                    name="numOfReviews"
                    className="input-control"
                  />
                </div>

                <div className="form-input">
                  <input id='fileUpload' type='file' multiple
                    accept='application/pdf, image/png'
                    onChange={handleFileEvent}
                    disabled={fileLimit}
                  />

                  {/* <label htmlFor='fileUpload'>
                    <a className={`btn btn-primary ${!fileLimit ? '' : 'disabled'}`}>Upload Files</a>
                  </label> */}

                  <div className="uploaded-files-list">
                    {uploadedFiles.map(({ file, preview }) => (
                      <div key={file.name}>
                        {file.type.startsWith('image/') && <img src={preview} alt={file.name} style={{ width: 100, height: 100 }} />}
                        <div>{file.name}</div>
                        <button onClick={() => removeFile(file.name)}>Remove</button>
                      </div>
                    ))}
                  </div>

                  <div className="saved-file-list">
                    {savedImagePreview?.map((data) => (
                      <div key={data._id}>
                        <img src={data.url} alt={data._id} style={{ width: 100, height: 100 }} />
                        <button onClick={() => removeFileFromServer(data)}>Remove</button>
                      </div>
                    ))}
                  </div>

                </div>
                <div className="form-input">
                  <button type="submit">Submit</button>
                </div>
              </Form>)}
          </Formik>
        </div>
      </div>
    </Fragment>
  );
};

export default NewProduct;
