import { axiosInstance } from "../../../Utilites/Axios";
import API_URL from "../../../config";


const userRegistration = (data) => {
    return axiosInstance.post(API_URL.Register, data)
}

const userLogin = (data) => {
    return axiosInstance.post(API_URL.Login, data)
}

const userForgotPassword = (data) => {
    return axiosInstance.post(API_URL.ForgotPasswrod, data)
}
const userUpdatePassword = (data) => {
    return axiosInstance.post(API_URL.updatePassword, data)
}

const userResetPassword = (data) => {
    return axiosInstance.put(`${API_URL.ResetPassword}/${data.token}`, data.myForm)
}

const userLogout = () => {
    return axiosInstance.post(API_URL.Logout)
}

const getUserAddress = () => {
    return axiosInstance.get(API_URL.getUserAddress)
}
const saveUserAddress = (obj) => {
    return axiosInstance.post(API_URL.saveUserAddress, obj)
}
const updateUserAddress = (obj) => {
    return axiosInstance.put(`${API_URL.updateUserAddress}/${obj.id}`, obj.data)
}

const deleteUserAddress = (obj) => {
    return axiosInstance.delete(`${API_URL.updateUserAddress}/${obj.id}`)
}



export const customerService = {
    userRegistration,
    userLogin,
    userForgotPassword,
    userUpdatePassword,
    userResetPassword,
    userLogout,
    getUserAddress,
    saveUserAddress,
    updateUserAddress,
    deleteUserAddress

}