import React, { useEffect, useState } from 'react'
import Yup from '../../../reusable-component/formik/Yup';
import { Regex, RegexMsg } from '../../../reusable-component/formik/ValidationRegex';
import { Form, Formik } from 'formik';
import FormikController from '../../../reusable-component/formik/FormikController';
import { useDispatch, useSelector } from 'react-redux';
// import { register } from '../../../actions/userAction';
// import { Link } from 'react-router-dom';
import { userRegistration } from '../../../redux-toolkit/slices/customerSlice/authSlice';
import { toast } from 'react-toastify';
import { isValidJWT } from '../../../Utilites/TokenValidation';
import { useNavigate } from 'react-router';

function SignupComponent() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [loading, setloading] = useState(false)
  const { userAuth } = useSelector(state => state)
  const { userToken } = userAuth

  useEffect(() => {
    if (isValidJWT() === true && userToken != "") {
      navigate("/")
    }
  }, [userToken])


  const signUpIntialState = {
    name: "",
    email: "",
    password: "",
    mobile: ""
  }


  const SignUpValidationSchema = Yup.object({
    name: Yup.string()
      .trim()
      .matches(Regex.acceptAlphabet, RegexMsg.acceptAlphabet)
      .required("Required")
      .wordLength("Word character length exceeded")
      .max(100, "Maximum 100 characters are allowed")
      .nullable(),
    email: Yup.string()
      .trim()
      .email("Invalid email")
      .required("Required")
      .nullable(),
    password: Yup.string()
      .required("Password Required")
      .matches(
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
      ),
    mobile: Yup.string()
      .trim()
      .max(10, "Please enter the 10 digit mobile number")
      .required("Required")
      .nullable(),
  });



  const registerSubmit = (value) => {
    setloading(true)
    const postData = { ...value }
    dispatch(userRegistration(postData)).then(resp => {
      // console.log(resp)
      if (resp.payload?.data?.success) {
        toast.success("Account created successfully")
      } else {
        toast.error(resp.payload)
      }
      setloading(false)
    }).catch(err => {
      toast.error("Something went wrong")
      setloading(false)
    })
  };


  return (
    <Formik
      initialValues={signUpIntialState}
      validationSchema={SignUpValidationSchema}
      onSubmit={(v) => registerSubmit(v)}>
      {(formik) => (
        <Form className="sign-up-form" >

          <h2 className="title">Sign up</h2>
          <span>Use your email for registration</span>
          <div className="input-field">
            <i className="fas fa-user"></i>
            <FormikController
              control="input"
              name="name"
              placeholder="Full Name"
            />
          </div>
          <div className="input-field">
            <i className="fas fa-envelope"></i>
            <FormikController
              control="input"
              name="email"
              placeholder="Email"
            />
          </div>
          <div className="input-field">
            <i className="fas fa-envelope"></i>
            <FormikController
              control="input"
              name="mobile"
              placeholder="Mobile"
            />
          </div>
          <div className="input-field">
            <i className="fas fa-lock"></i>
            <FormikController
              type="password"
              control="input"
              placeholder="Password"
              name="password"
            />
          </div>

          <button type="submit" className="btn" disabled={loading}> {loading ? "Loading..." : "Create Account"}</button>
          <p className="social-text">Or Sign up with social platforms</p>
          <div className="social-media">
            <a href="https://www.instagram.com/mcaprintwala" className="social-icon" aria-label="Instagram" >
              <i className="fab fa-instagram"></i>
            </a>
            {/* <a href="#" className="social-icon">
              <i className="fab fa-twitter"></i>
            </a>
            <a href="#" className="social-icon">
              <i className="fab fa-google"></i>
            </a>
            <a href="#" className="social-icon">
              <i className="fab fa-linkedin-in"></i>
            </a> */}
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default SignupComponent