import React from 'react'
import AccordionItem from '../../../reusable-component/accordion/AccordionItem'
import UserAddressForm from './UserAddressForm'

function UserAddress() {
    return (
        <AccordionItem
            title={'Address'}
            index={2}
            description={
                <UserAddressForm />
            }>
        </AccordionItem>
    )
}

export default UserAddress