import React from 'react'
import AccordionItem from '../../../reusable-component/accordion/AccordionItem'
import { useSelector } from 'react-redux';

function OrderHistory() {
    const { myOrders } = useSelector(state => state);
    const orderList = myOrders?.orders;
    return (
        <AccordionItem title={'Order History'}
            index={0}
            description={orderList?.length > 0 ?
                orderList?.map((oInfo, i) => (
                    <div className="ordrhistrybx mt-4" key={i}>
                        <div className="ordrhistrymnu">
                            <div className="pdtifobx box">Product Info</div>
                            <table className='clientdash'>
                                <tr>
                                    <td> <figure className="imgbox mb-0">
                                        <img src={oInfo?.orderItems[0]?.image} style={{ width: "70px" }} alt="prouduct name" className="img_custom" /></figure></td>
                                    <td>{oInfo?.orderItems[0]?.name}</td>
                                    <td><h5 className="txth5"><span>Qyt. </span>{oInfo?.orderItems[0]?.quantity}</h5></td>
                                    <td><h5 className="txth5">Rs. <span>{oInfo?.totalPrice}</span></h5></td>
                                    <td><h5 className="txth5">Order Status : {oInfo?.orderStatus}</h5></td>
                                    <td><button type="button">View More </button></td>
                                    {/* <td><button type="button">View More <i className="fa-solid fa-chevron-down" /></button></td> */}
                                </tr>
                            </table>
                        </div>
                        <div className="collapse" id={`collapseExample${i}`}>
                            <table className='clientdash navi_grt'>
                                <tr>
                                    <td> <h5 className="txth5">
                                        Payment Information
                                    </h5>
                                        <p>Payment status : {oInfo?.paymentInfo?.status}</p></td>
                                    <td><h5 className="txth5">
                                        Shipping Information
                                    </h5>
                                        <p>{`${oInfo?.shippingInfo[0]?.address}, ${oInfo?.shippingInfo[0]?.city}, ${oInfo?.shippingInfo[0]?.state}, ${oInfo?.shippingInfo[0]?.pinCode} `}</p>
                                        <p>Contact Number : {oInfo?.shippingInfo[0]?.phoneNo}</p></td>
                                </tr>
                            </table>
                        </div>
                    </div>
                )) : <div className="ordrhistrybx mt-4 text-center">Order not found</div>} />

    )
}


export default OrderHistory

