import React, { Fragment, useState } from "react";
import "./ForgotPassword.css";
// import Loader from "../layout/Loader/Loader";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import { useDispatch } from "react-redux";
// import { clearErrors, forgotPassword } from "../../actions/userAction";
// import { useAlert } from "react-alert";
import MetaData from "../layout/MetaData";
import { userForgotPassword } from "../../redux-toolkit/slices/customerSlice/authSlice";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

// NOT USED COMPONENT
const ForgotPassword = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [loader, setLoader] = useState(false);

  const forgotPasswordSubmit = (e) => {
    e.preventDefault();
    setLoader(true)
    const postData = {
      email
    }
    dispatch(userForgotPassword(postData)).then(resp => {
      console.log(resp)
      if (resp?.payload?.data?.success) {
        setLoader(false)
        toast.success(resp?.payload?.data?.message)
      } else {
        setLoader(false)
        toast.error(resp?.payload)
      }
    }).catch(err => {
      toast.error("Something went wrong")
      setLoader(false)
    })
  };

  return (
    <Fragment>
      <MetaData title="Forgot Password" />
      <div className="forgotPasswordContainer">
        <div className="forgotPasswordBox">
          <h2 className="forgotPasswordHeading">Forgot Password</h2>

          <form
            className="forgotPasswordForm"
            onSubmit={forgotPasswordSubmit}
          >
            <div className="forgotPasswordEmail">
              <MailOutlineIcon />
              <input
                type="email"
                placeholder="Email"
                required
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            {loader ? <p>Processing..</p> : <input
              type="submit"
              value="Send"
              className="forgotPasswordBtn"
            />}

          </form>

          <Link to="/login">Got to login</Link>
        </div>

      </div>
    </Fragment>
  );
};

export default ForgotPassword;
