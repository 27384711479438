import React, { useRef, useEffect, useState } from 'react'
import { useSelector, useDispatch } from "react-redux";
import {
  clearErrors,
  clearSearchProudctList,
  getProductDetails,
  // newReview,
} from "../../../actions/productAction";
// import { addItemsToCart, addItemsToWishlist, removeItemsFromWishlist } from "../../../actions/cartAction";
import { useAlert } from 'react-alert';
import { Link, useNavigate, useParams } from 'react-router-dom';
// import { dirIcons, dirImages } from "../../../Utilites/GetImage";
// import Loader from '../../layout/Loader/Loader';
// import parse from 'html-react-parser';
import { uniqueId } from 'lodash';
import DetailsSlider from './DetailsSlider/DetailsSlider';
import './ProductDetails.scss';
import AccordionWrapper from "../../../reusable-component/accordion/AccordionWrapper";
import AccordionItem from "../../../reusable-component/accordion/AccordionItem";
import InnerImageZoom from "react-inner-image-zoom";
import Rating from "react-rating";
import { addToCart } from '../../../redux-toolkit/slices/cartSlice';

function ProductDetails() {

  const params = useParams();
  const dispatch = useDispatch();
  const alert = useAlert();
  const navigate = useNavigate();

  const { product, loading, error } = useSelector(
    (state) => state.productDetails
  );




  const { success, error: reviewError } = useSelector(
    (state) => state.newReview
  );


  const { userAuth } = useSelector((state) => state);
  const { isAuthenticated } = userAuth

  const [quantity, setQuantity] = useState(1);
  const [productSize, setProductSize] = useState("");

  const [discription, setDiscription] = useState([]);
  const [instruction, setInstruction] = useState([]);

  const [previewImage, setPriviewImage] = useState("#")



  const sizeArray = product?.size



  const increaseQuantity = () => {
    if (product.Stock <= quantity) return;
    // console.log("ee")
    const qty = quantity + 1;
    setQuantity(qty);
  };

  const decreaseQuantity = () => {
    if (1 >= quantity) return;

    const qty = quantity - 1;
    setQuantity(qty);
  };




  const { wishList } = useSelector((state) => state.cart);


  const addToCartHandler = (product) => {

    // console.log(product)
    let reqData = {
      "productId": params?.product_id,
      "productQyt": quantity,
      "productSize": productSize,
      "productCategory": product?.category
    }
    if (product?.size?.length > 0 && product?.size[0] !== "default" && isAuthenticated) {

      if (productSize !== "") {


        dispatch(addToCart(reqData)).then(resp => { alert.success("Item Added To Cart"); navigate("/cart"); }).catch(err => {
          alert.error(err.response.data.message)
          navigate("/login");
        });

      } else {
        alert.error("Please select the size")
      }
    } else {
      if (!isAuthenticated) {
        alert.error("Please login to add product")
      } else {
        let productSize = product?.size[0]

        dispatch(addToCart({ ...reqData, productSize }))
          .then(resp => { alert.success("Item Added To Cart"); navigate("/cart"); })
          .catch(err => {
            alert.error(err.response.data.message)
            navigate("/login");
          });
      }


    }
  };


  useEffect(() => {

    dispatch(clearSearchProudctList())

    dispatch(getProductDetails(params?.product_id));

  }, [params, reviewError, success]);



  useEffect(() => {
    if (typeof (product?.description) !== 'undefined') {
      setDiscription(Object.values(product?.description))
      setInstruction(Object.values(product?.instruction))

      setPriviewImage(product?.image)

    }
  }, [product])

  return (
    <React.Fragment>
      {<div className="product-details">
        <ul className="breadcrumbs">
          <li>
            <Link to="/" className="fas fa-home"></Link>
          </li>
          <li>
            <Link to={`/products/${product.category}`}>{product.category}</Link>
          </li>
          <li>
            {product.name}
          </li>
        </ul>
        <div className="top-div">
          <div className="preview">
            {
              (product && product.images && product.images.length) ?
                <DetailsSlider product={product} /> :
                <div className="image-box">
                  <InnerImageZoom
                    src={previewImage}
                    alt='details image'
                    zoomType={'hover'}
                  />
                </div>
            }

          </div>

          <div className="product-info">
            <div className="heading row">
              <h1>{product?.name}</h1>
            </div>
            <div className="rating row">
              <Rating
                className="rating-wrapper"
                readonly={true}
                initialRating={4.5}
                emptySymbol="fa fa-star-o"
                fullSymbol="fa fa-star"
                fractions={2}
              />
              <h3>(2)</h3>
            </div>
            {
              product?.size?.length > 1 &&
              <div className="row">
                <h3>Size: </h3>
                <div className="select-dropdown">
                  <select onChange={(e) => setProductSize(e.target.value)} value={productSize}>
                    <option key={uniqueId()} value="">Select</option>
                    {product?.size?.map((item) => (
                      <option key={uniqueId()} value={item}>{item}</option>
                    ))}
                  </select>
                </div>
              </div>
            }
            <div className="stock row">
              <h3>Status: </h3>
              <h4 className={product?.Stock < 1 ? 'out-of-stock' : 'in-stock'}>{product?.Stock < 1 ? 'Sold out' : 'In Stock'}</h4>
            </div>
            <div className="price row">
              <h3>Price: </h3>
              <h4>INR {product?.price}</h4>
            </div>
            <div className="buttons row">
              <button
                className="primary-cta"
                disabled={product.Stock === 0}
                onClick={() => addToCartHandler(product)}>Add to cart
              </button>
              <button className="primary-cta"
                disabled={product.Stock === 0}
                onClick={() => addToCartHandler(product)}>Buy Now
              </button>
            </div>
            <div className="row">
              <h3>Quantity: </h3>
              <div className="quantity">
                <button className="btn-minus" onClick={decreaseQuantity}>-</button>
                <input className="quantity-field" type="text" value={quantity} min={0} max={10} readOnly={true} />
                <button className="btn-plus" onClick={increaseQuantity}>+</button>
              </div>
            </div>
            <AccordionWrapper>
              {discription?.length > 0 && <AccordionItem
                title={'Description'}
                index={0}
                description={<p>{discription}</p>}
              />}

              {instruction?.length > 0 && <AccordionItem
                title={'Instruction'}
                index={1}
                description={<p>{instruction}</p>}
              />}
            </AccordionWrapper>
          </div>
        </div>
      </div>
      }
    </React.Fragment>
  )
}

export default ProductDetails