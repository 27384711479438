import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import './HeaderMenu.scss';
// import { isValidJWT } from '../../../../Utilites/TokenValidation';


const HeaderMenu = () => {
    const { cart, cartData, userAuth } = useSelector(state => state);
    const { wishList } = cart;
    const [isMenuOpen, setIsMenuOpen] = useState(false);


    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (!document.getElementById("mySidenav").contains(event.target)) {
                setIsMenuOpen(false);
                document.getElementById("mySidenav").style.width = '0';
                event.preventDefault();
            }
        };

        if (isMenuOpen) {
            document.body.addEventListener('click', handleOutsideClick, true);
        }

        return () => {
            if (isMenuOpen) {
                document.body.removeEventListener('click', handleOutsideClick, true);
            }
        };
    }, [isMenuOpen]);

    function openNav() {
        setIsMenuOpen(true);
        document.getElementById("mySidenav").style.width = "250px";
    }

    // console.log(userAuth)
    return (
        <div className="header-menu">
            <div className="menu-items wishlist-items">
                <Link to="/wishlist">
                    <i className="fa fa-heart fa-lg" aria-hidden="true" />
                    {wishList && wishList.length ? <span className="wishlist-count">{wishList.length}</span> : ''}
                </Link>
            </div>
            <div className="menu-items cart-items">
                <Link to="/cart">
                    <i className="fa fa-shopping-cart fa-lg" aria-hidden="true" />
                    {cartData.cart && cartData.cart.length ? <span className="shopping-cart-count">{cartData.cart.length}</span> : ''}
                </Link>
            </div>
            {userAuth.isAuthenticated ? <div className="menu-items">
                <Link to="/customer-dashboard">
                    <i className="fa fa-user fa-lg" aria-hidden="true" />
                </Link>
            </div> : <div className="menu-items">
                <Link to="/login">
                    <i className="fa fa-user fa-lg" aria-hidden="true" />
                </Link>
            </div>}

            <div className="menu-items">
                <span onClick={() => openNav()} style={{ cursor: "pointer" }}>
                    <i className="fa fa-bars fa-lg" aria-hidden="true" />
                </span>
            </div>
        </div>
    );
};

export default HeaderMenu;
