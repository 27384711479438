import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import {
  newProductReducer,
  newReviewReducer,
  productDetailsReducer,
  productReducer,
  productReviewsReducer,
  productsReducer,
  reviewReducer,
  searchProductsReducer,
  productCategory
} from "./reducers/productReducer";

import {
  allUsersReducer,
  forgotPasswordReducer,
  profileReducer,
  userDetailsReducer,
  userReducer,
} from "./reducers/userReducer";

import { cartReducer } from "./reducers/cartReducer";
import {
  allOrdersReducer,
  myOrdersReducer,
  newOrderReducer,
  orderDetailsReducer,
  orderReducer,
} from "./reducers/orderReducer";
import { cartSliceReducer } from "./redux-toolkit/slices/cartSlice";
import { userSliceReducer } from "./redux-toolkit/slices/userSlice";
import { adminProductSliceReducer } from "./redux-toolkit/slices/adminSlice/adminProductSlice";
import { adminOrderSliceReducer } from "./redux-toolkit/slices/adminSlice/adminOrderSlice";
import { adminUserSliceReducer } from "./redux-toolkit/slices/adminSlice/adminUserSlice";
import { authSliceReducer } from "./redux-toolkit/slices/customerSlice/authSlice";
import { wishlistSliceReducer } from "./redux-toolkit/slices/customerSlice/wishlistSlice";


const reducer = combineReducers({
  cartData: cartSliceReducer,
  userData: userSliceReducer,
  products: productsReducer,
  searchProductList: searchProductsReducer,
  productDetails: productDetailsReducer,
  user: userReducer,
  profile: profileReducer,
  forgotPassword: forgotPasswordReducer,
  cart: cartReducer,
  newOrder: newOrderReducer,
  myOrders: myOrdersReducer,
  orderDetails: orderDetailsReducer,
  newReview: newReviewReducer,
  newProduct: newProductReducer,
  product: productReducer,
  allOrders: allOrdersReducer,
  order: orderReducer,
  allUsers: allUsersReducer,
  userDetails: userDetailsReducer,
  productReviews: productReviewsReducer,
  review: reviewReducer,
  productCategory: productCategory,


  // new reduces customer
  userAuth: authSliceReducer,
  wishlist: wishlistSliceReducer,

  // admin 
  aProducts: adminProductSliceReducer,
  aOrders: adminOrderSliceReducer,
  aUsers: adminUserSliceReducer
});

// let initialState = {
//   cart: {
//     cartItems: localStorage.getItem("cartItems")
//       ? JSON.parse(localStorage.getItem("cartItems"))
//       : [],
//     shippingInfo: localStorage.getItem("shippingInfo")
//       ? JSON.parse(localStorage.getItem("shippingInfo"))
//       : {},
//     wishList: localStorage.getItem("wishList")
//       ? JSON.parse(localStorage.getItem("wishList"))
//       : []
//   },
// };

const middleware = [thunk];

const store = createStore(
  reducer,
  // initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
