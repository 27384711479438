import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import userService from "../services/user.service";
import { isValidJWT } from "../../Utilites/TokenValidation";


const initialState = {
    userAddress: {},
    loading: false,
    error: false,
    isTokenExp: isValidJWT()
};




export const userAddress = createAsyncThunk(
    "userData/address",
    async (obj, thunkAPI) => {
        try {
            console.log("heelo")
            const response = await userService.getUserAddress();
            return response;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);



export const userDataSlice = createSlice({
    name: 'userData',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(userAddress.pending, (state) => {
                state.userAddress = {}
            })
            .addCase(userAddress.fulfilled, (state, action) => {
                state.userAddress = action.payload.data === "" ? {} : action.payload.data
                // state.loading = false
            })
            .addCase(userAddress.rejected, (state) => {
                state.userAddress = {}
                // state.loading = true
                // state.error = true
            })
    },
})

// export const { clearCart } = userDataSlice.actions
export const userSliceReducer = userDataSlice.reducer