import React from 'react'

function OrderSuccess() {
  return (
    <div class="cstmradrespeg navi_grr">
    <div class="container">
      <div class="titlbox">
        <h3 class="txth3">Addresses</h3>
        <div class="radbtn"> <a href="#"> Return to Account details</a></div>
        <div class="anadresbtn">
          <button type="button" class="btn">Add a new address</button>
        </div>
      </div>
      <div class="defltbox">
        <h4 class="txth4">Default</h4>
        <p class="textsmall">Sharjeel Shaikh</p>
        <p class="textsmall">xyz</p>
        <p class="textsmall">xyz</p>
        <p class="textsmall">Azamgarh</p>
        <p class="textsmall">India</p>
        <div class="edtdltbtn">
          <button type="button" class="btn">Edit</button>
          <button type="button" class="btn">Delete</button>
        </div>
      </div>
    </div>
  </div>
  )
}

export default OrderSuccess