import React, { useState } from 'react'
// import { useDispatch } from "react-redux";
import { useAlert } from "react-alert";


import { axiosInstanceCookie } from '../../../Utilites/Axios';
import API_URL from '../../../config';
import { useNavigate } from 'react-router';
// import { contactForm } from '../../../actions/contactFormAction'



function Contact() {
    const alert = useAlert();
    const navigate = useNavigate();

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [query, setQuery] = useState("");
    const [domain, setDomain] = useState("thenoveltyprints.in");


    const sendQuery = (e) => {
        e.preventDefault();

        if (name === "" || email === "" || phone === "" || query === "") {
            alert.success("Please enter all required fields")
        } else {
            const postData = {
                name,
                email,
                phone,
                query,
                domain
            }
            const authToken = localStorage.getItem("authToken")
            const config = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${authToken}`
                },
            };
            axiosInstanceCookie.post(
                API_URL.Contact,
                postData,
                config
            ).then(response => {
                alert.success("Your query has been submitted and we will get back to you within 24 hours")
                setName("")
                setEmail("")
                setPhone("")
                setQuery("")
                // navigate("/")
            }).catch(err => alert.error(err.message))
        }



    };


    return (
        <div className="cntctsec breadcrumbs-wrapper">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8">
                        <form className="frmbox" onSubmit={sendQuery}>
                            <div className="row">
                                <div className="col-sm-6 mb-3">
                                    <input type="Name" className="form-control form-control-lg" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} /> </div>
                                <div className="col-sm-6 mb-3">
                                    <input type="email" className="form-control form-control-lg" placeholder="Email address" value={email} onChange={(e) => setEmail(e.target.value)} /> </div>
                                <div className="col-12 mb-3">
                                    <input type="tel" className="form-control form-control-lg" placeholder="Phone Number" value={phone} onChange={(e) => setPhone(e.target.value)} /> </div>
                                <div className="col-12 mb-3">
                                    <textarea className="form-control form-control-lg" rows={3} textarea="query" value={query} onChange={(e) => setQuery(e.target.value)} />
                                </div>
                                <div className="frmbtn">
                                    <button type="submit" className="btn btn-secondary btn-lg">Send</button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="col-lg-4">
                        <div className="adrsbox">
                            <p><span>Phone :</span> +91 9761100617</p>
                            <p><span>WhatsApp :</span> +91 9761100617</p>
                            <p><span>Email :</span>mcaprintwala@gmail.com</p>
                            <h6 className="mb-0">Customer Service</h6>
                            <p>Monday - Saturday <br />10:30 AM - 6 PM (IST)</p>


                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Contact