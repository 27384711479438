import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { customerService } from "../../services/customerService/authService";
import { isValidJWT } from "../../../Utilites/TokenValidation";

const localUserData = JSON.parse(localStorage.getItem("userecm"))
const localUserToken = localStorage.getItem("authToken")
let isAuthenticated = false

if (localUserToken && localUserData) {
    isAuthenticated = true
}

const initialState = {
    userLoginData: localUserData ?? {},
    userToken: localUserToken ?? "",
    isAuthenticated: isAuthenticated,

    userAddress: [],
    loading: false,
    error: false,
    isTokenExp: isValidJWT()
};




export const userRegistration = createAsyncThunk(
    "auth/userRegistration",
    async (obj, thunkAPI) => {
        try {
            const response = await customerService.userRegistration(obj);
            return response;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);
export const userLogin = createAsyncThunk(
    "auth/userLogin",
    async (obj, thunkAPI) => {
        try {
            const response = await customerService.userLogin(obj);
            return response;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);
export const userForgotPassword = createAsyncThunk(
    "auth/userForgotPassword",
    async (obj, thunkAPI) => {
        try {
            const response = await customerService.userForgotPassword(obj);
            return response;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);


export const userResetPassword = createAsyncThunk(
    "auth/userResetPassword",
    async (obj, thunkAPI) => {
        try {

            const response = await customerService.userResetPassword(obj);
            return response;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);


export const userUpdatePassword = createAsyncThunk(
    "auth/userUpdatePassword",
    async (obj, thunkAPI) => {
        try {
            const response = await customerService.userUpdatePassword(obj);
            return response;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);


export const userLogout = createAsyncThunk(
    "auth/userLogout",
    async (obj, thunkAPI) => {
        try {
            const response = await customerService.userLogout(obj);
            return response;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);


export const getUserAddress = createAsyncThunk(
    "auth/getUserAddress",
    async (obj, thunkAPI) => {
        try {

            const response = await customerService.getUserAddress();
            return response;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const saveUserAddress = createAsyncThunk(
    "auth/saveUserAddress",
    async (obj, thunkAPI) => {
        try {

            const response = await customerService.saveUserAddress(obj);
            return response;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const updateUserAddress = createAsyncThunk(
    "auth/updateUserAddress",
    async (obj, thunkAPI) => {
        try {

            const response = await customerService.updateUserAddress(obj);
            return response;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const deleteUserAddress = createAsyncThunk(
    "auth/deleteUserAddress",
    async (obj, thunkAPI) => {
        try {

            const response = await customerService.deleteUserAddress(obj);
            return response;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);



export const authSlice = createSlice({
    name: 'authSlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // registration
            .addCase(userRegistration.pending, (state) => {
                state.userLoginData = {}
                state.userToken = {}
                state.isAuthenticated = false
            })
            .addCase(userRegistration.fulfilled, (state, action) => {
                state.userLoginData = action.payload.data.user
                state.userToken = action.payload.data.token
                state.isAuthenticated = true

                // set data in localstorage
                localStorage.setItem("authToken", action.payload.data.token)
                localStorage.setItem("userecm", JSON.stringify(action.payload.data.user))
            })
            .addCase(userRegistration.rejected, (state) => {
                state.userLoginData = {}
                state.userToken = {}
                state.isAuthenticated = false
            })

            // login
            .addCase(userLogin.pending, (state) => {
                state.userLoginData = {}
                state.userToken = {}
                state.isAuthenticated = false
            })
            .addCase(userLogin.fulfilled, (state, action) => {
                state.userLoginData = action.payload.data.user
                state.userToken = action.payload.data.token
                state.isAuthenticated = true

                // set data in localstorage
                localStorage.setItem("authToken", action.payload.data.token)
                localStorage.setItem("userecm", JSON.stringify(action.payload.data.user))
            })
            .addCase(userLogin.rejected, (state) => {
                state.userLoginData = {}
                state.userToken = {}
                state.isAuthenticated = false
            })

            // user logout
            .addCase(userLogout.pending, (state) => {
                localStorage.clear()
                state.userLoginData = {}
                state.userToken = {}
                state.isAuthenticated = false
            })
            .addCase(userLogout.fulfilled, (state) => {
                localStorage.clear()
                state.userLoginData = {}
                state.userToken = {}
                state.isAuthenticated = false
            })

            //  forgot password
            .addCase(userForgotPassword.pending, (state) => {
                state.loading = true
            })
            .addCase(userForgotPassword.fulfilled, (state, action) => {
                state.loading = false
            })
            .addCase(userForgotPassword.rejected, (state) => {
                state.loading = false
            })

            // user address
            .addCase(getUserAddress.pending, (state) => {
                state.userAddress = []
            })
            .addCase(getUserAddress.fulfilled, (state, action) => {
                state.userAddress = action.payload.data === "" ? [] : action.payload.data
            })
            .addCase(getUserAddress.rejected, (state) => {
                state.userAddress = []
            })
    },
})

// export const { clearCart } = userDataSlice.actions
export const authSliceReducer = authSlice.reducer