import React from "react";
import { Field, ErrorMessage } from "formik";

function Input(props) {
  const { name, label, lableClassName, ...rest } = props;
  // console.log("label",label);
  return (
    <React.Fragment>
      {typeof label !== "undefined" && (
        <label htmlFor={name} className={lableClassName}> {label}</label>
      )}

      <Field name={name} {...rest} />

      <ErrorMessage name={name}>
        {(msg) => (
          <p className="error-message" style={{ color: "red" }}>
            {msg}
          </p>
        )}
      </ErrorMessage>
    </React.Fragment>
  );
}
export default Input;
