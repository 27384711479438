import { axiosInstance } from "../../Utilites/Axios";
import API_URL from "../../config";

const getCartData = () => {
    return axiosInstance.get(API_URL.cart);
};

const addToCart = (obj) => {
    return axiosInstance.post(API_URL.cart, obj);
};

const updateCart = (obj) => {
    return axiosInstance.put(API_URL.cart, obj);
};

const removeCartItem = (obj) => {
    return axiosInstance.post(API_URL.deleteCartItem, obj);
};


const cartServices = {
    getCartData,
    addToCart,
    updateCart,
    removeCartItem
};

export default cartServices;