import React, { Fragment, useEffect } from "react";
import { DataGrid } from "@material-ui/data-grid";
import "./productList.css";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useAlert } from "react-alert";
import { Button } from "@material-ui/core";
import MetaData from "../layout/MetaData";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import SideBar from "./Sidebar";
// import { DELETE_PRODUCT_RESET } from "../../constants/productConstants";
import { getAllProducts, deleteProduct } from "../../redux-toolkit/slices/adminSlice/adminProductSlice";

const ProductList = ({ history }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const alert = useAlert();

  const { aProducts, userAuth } = useSelector((state) => state);
  const { products } = aProducts
  const role = userAuth?.userLoginData?.role
  if (role !== "admin") {
    navigate("/")
  }

  const deleteProductHandler = (id) => {
    dispatch(deleteProduct(id)).then(resp => {
      dispatch(getAllProducts());
    }).catch(err => console.log(err))


  };

  useEffect(() => {
    dispatch(getAllProducts());
  }, []);


  const columns = [
    { field: "_id", headerName: "Product ID", minWidth: 200, flex: 0.5 },

    {
      field: "name",
      headerName: "Name",
      minWidth: 350,
      flex: 1,
    },
    {
      field: "Stock",
      headerName: "Stock",
      type: "number",
      minWidth: 150,
      flex: 0.3,
    },

    {
      field: "price",
      headerName: "Price",
      type: "number",
      minWidth: 270,
      flex: 0.5,
    },

    {
      field: "actions",
      flex: 0.3,
      headerName: "Actions",
      minWidth: 150,
      type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <Fragment>
            {console.log(params)}
            <Link to={`/admin/product/edit/${params.id}`}>
              <EditIcon />
            </Link>

            <Button
              onClick={() =>
                deleteProductHandler(params.id)
              }
            >
              <DeleteIcon />
            </Button>
          </Fragment>
        );
      },
    },
  ];

  return (
    <Fragment>
      <MetaData title={`ALL PRODUCTS - Admin`} />

      <div className="dashboard">
        <SideBar />
        <div className="productListContainer">
          <h1 id="productListHeading">ALL PRODUCTS</h1>

          <DataGrid
            rows={products}
            columns={columns}
            pageSize={10}
            disableSelectionOnClick
            className="productListTable"
            autoHeight
            getRowId={(row) => row._id}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default ProductList;
