import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { saveShippingInfo } from "../../actions/cartAction";
import { useAlert } from "react-alert";
import { State, City } from "country-state-city";
import "./checkout.scss";
import { getUserAddress, saveUserAddress, updateUserAddress } from "../../redux-toolkit/slices/customerSlice/authSlice";
import Yup from "../../reusable-component/formik/Yup";
import { toast } from "react-toastify";
import { Formik, Form } from "formik";
import FormikController from "../../reusable-component/formik/FormikController";
import { convertToFormikSelectJson } from "../../Utilites/convertToFormikSelectJson";

const CheckoutSteps = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { cartData, userAuth } = useSelector((state) => state);

  const cartItems = cartData.cart
  const { userAddress, userLoginData } = userAuth
  const user = userLoginData
  const customerAddress = userAddress

  const [editAddress, setEditAddress] = useState(false);
  const [newAddress, setNewAddress] = useState(false);
  const [radioSelectAddr, setRadioSelectAddr] = useState({})
  const [updateAddrId, setUpdateAddrId] = useState("")
  const [state, setState] = useState([])
  const [selectedState, setSelectedState] = useState("")
  const [city, setCity] = useState([])




  useEffect(() => {
    dispatch(getUserAddress())
    const IndiaState = State.getStatesOfCountry("IN")
    const stateOption = convertToFormikSelectJson("isoCode", "name", IndiaState)
    setState(stateOption)
  }, [])


  useEffect(() => {
    if (selectedState !== '') {
      const cityData = City.getCitiesOfState("IN", selectedState)
      const cityOption = convertToFormikSelectJson("isoCode", "name", cityData)
      setCity(cityOption)
    }
  }, [selectedState])




  const shippingSubmit = () => {

    dispatch(
      saveShippingInfo({
        email: user?.email,
        firstName: user?.name,
        lastName: "",
        address: radioSelectAddr?.address,
        city: radioSelectAddr?.city,
        state: radioSelectAddr?.state,
        country: radioSelectAddr?.country,
        pinCode: radioSelectAddr?.pinCode,
        phoneNo: user?.mobile,
      })
    );

    const subtotal = cartItems.reduce(
      (acc, item) => acc + item.qty * item?.productDetails?.[0]?.price,
      0
    );
    const shippingCharges = 0;
    const tax = 0;
    const totalPrice = subtotal;
    const data = {
      subtotal,
      shippingCharges,
      tax,
      totalPrice,
    };

    sessionStorage.setItem("orderInfo", JSON.stringify(data));
    navigate("/payment");
  };


  useEffect(() => {
    if (customerAddress?.length > 0) {
      setRadioSelectAddr(customerAddress[0])
      setUpdateAddrId(customerAddress[0]?._id)
    }

  }, [customerAddress])

  useEffect(() => {
    if (!editAddress) {
      setRadioSelectAddr(customerAddress[0])
      setUpdateAddrId(customerAddress[0]?._id)
    }
  }, [editAddress])



  // form edit
  // Validation schema
  const validationSchema = Yup.object().shape({
    address: Yup.string().required('Address is required').nonNullable("Address is required"),
    country: Yup.string().required('Country is required').nonNullable("Country is required"),
    city: Yup.string().required('City is required').nonNullable('City is required'),
    state: Yup.string().required('State is required').nonNullable('State is required'),
    pinCode: Yup.string().required('Pincode is required').nonNullable('Pincode is required'),
  });

  const initialValues = {
    address: radioSelectAddr?.address ?? null,
    pinCode: radioSelectAddr?.pinCode ?? null,
    country: radioSelectAddr?.country ?? 'IN',
    city: radioSelectAddr?.city ?? null,
    state: radioSelectAddr?.state ?? null,
  }
  const submitHandler = (values) => {
    if (editAddress) {
      dispatch(updateUserAddress({ id: updateAddrId, data: values }))
        .then(resp => {
          setEditAddress(false)
          if (resp.payload?.data) {
            setEditAddress(false)
            dispatch(getUserAddress())
            toast.success("Address Update")
          } else {
            toast.error("Something went wrong")
          }
        }).catch(err => toast.error("Something went wrong"))

    }
    if (newAddress) {
      dispatch(saveUserAddress(values)).then(resp => {
        setNewAddress(false)
        if (resp.payload?.data) {
          dispatch(getUserAddress())
          toast.success("Address Saved")
        } else {
          toast.error("Something went wrong")
        }
      }).catch(err => toast.error("Something went wrong"))
    }
  }







  return (
    <div className="checkout checkoutpg">
      <main>
        {/* address form */}
        {(editAddress || newAddress) &&
          <div className="address__Details" >
            <div className="header-row">
              <h4>Address Details</h4>
            </div>
            <div className="address__form">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={submitHandler}
                enableReinitialize={true}
              >
                {(formik) => (
                  <Form className="address__form">
                    <div className="form-input">
                      <FormikController
                        control="input"
                        className="input-control"
                        label="Address"
                        name="address"
                      />

                    </div>
                    <div className="form-input">
                      <FormikController
                        control="input"
                        className="input-control"
                        label="Country"
                        name="country"
                        disabled={true}
                      />
                    </div>
                    <div className="form-input">
                      <FormikController
                        control="select"
                        className="input-control"
                        label="State"
                        name="state"
                        options={state}
                        onChange={
                          (e) => {
                            formik.setFieldValue("state", e.target.value)
                            setSelectedState(e.target.value)
                          }
                        }
                      />
                    </div>
                    <div className="form-input">
                      <FormikController
                        control="select"
                        className="input-control"
                        label="City"
                        name="city"
                        options={city}
                      />
                    </div>
                    <div className="form-input">
                      <FormikController
                        control="input"
                        className="input-control"
                        label="Pin Code"
                        name="pinCode"
                      />
                    </div>
                    <div className="form-input">
                      <button type="submit">Submit</button>
                    </div>
                    <div className="form-input">
                      <a className="btn btn-close" href={() => false} onClick={() => setEditAddress(false)} >Close</a>
                    </div>
                  </Form>

                )}
              </Formik>
            </div>
          </div>}

        {/* Address List */}
        {!editAddress && !newAddress &&
          <div className="address__Details" >
            <div className="available__address">
              {userAddress?.length === 0 ?
                <div>
                  <h3>Please add the shipping address to complete the order</h3>
                  <button className="btn btn-primary" onClick={() => setNewAddress(true)}>Add Address</button>
                </div>
                :
                <div>
                  <h4>Select Address</h4>
                  {userAddress?.map((item, i) => (
                    <div className="address" key={item._id}>
                      <input type='radio' name="userAddress" value={item._id}
                        onChange={() => {
                          setRadioSelectAddr(item)
                          setUpdateAddrId(item._id)
                        }}
                        defaultChecked={i === 0}

                      />
                      <div className="address-detail">
                        <p> {item?.address}, {item?.state}, {item?.city}, {item?.country}, {item?.pinCode}</p>
                      </div>
                    </div>
                  ))}

                  <button onClick={() => setEditAddress(true)} > Edit</button>
                </div>
              }
            </div>
          </div>}




        <div className="order__summary">
          <div className="header-row">
            <h4>Order Summary</h4>{" "}
          </div>
          <table>
            <thead>
              <tr>
                <th className="left">PRODUCT NAME</th>
                <th className="p-1">QTY</th>
                <th>SUBTOTAL</th>
              </tr>
            </thead>
            <tbody>
              {cartItems &&
                cartItems.map((item, i) => (
                  <tr key={i}>
                    <td className="product--desc">
                      {" "}
                      {item?.productDetails?.[0]?.name}
                      <span>{item?.productDetails?.[0]?.productCategory}</span>
                    </td>
                    <td className="p-1 center">{item.qty}</td>
                    <td>INR {item.qty * item?.productDetails?.[0]?.price}</td>
                  </tr>
                ))}
            </tbody>
            <tfoot>
              <tr>
                <td className="left">
                  Subtotal
                </td>
                <td></td>
                <td>{`INR ${cartItems.reduce(
                  (acc, item) => acc + item.qty * item?.productDetails?.[0]?.price,
                  0
                )}`}</td>
              </tr>
              <tr>
                <td className="left">
                  Shipping &amp; Handling [Weight Based]
                </td>
                <td></td>
                <td>Free</td>
              </tr>
              <tr>
                <td className="left">
                  <b>Grand Total</b>
                </td>
                <td></td>
                <td>
                  <b>{`INR ${cartItems.reduce(
                    (acc, item) => acc + item.qty * item?.productDetails?.[0]?.price,
                    0
                  )}`}</b>
                </td>
              </tr>
            </tfoot>
          </table>
          <div className="cta">
            {/* <RazorpayButton /> */}
            <button className="primary-cta" onClick={() => shippingSubmit()}> Proceed to Payment </button>
          </div>
        </div>
      </main>
    </div>

  );
};

export default CheckoutSteps;
