import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import React from "react";
import { uniqueId } from "lodash";
import './ProductSlider.scss';

export const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    initialSlide: 0,
    swipe: true,
    responsive: [
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 4
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 3
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 2
            }
        }
    ]
};
const ProductSlider = ({ heading, productData }) => {
    const { productCategory = [] } = productData || {};
    return (
        <section className="products-container">
            <div className="products">
                <div className="title">
                    <h1>{heading}</h1>
                </div>
                <hr />
                <div className="slider-container">
                    <Slider {...settings}>
                        {productCategory.map(item=>(
                            <div className="product top-catg" key={uniqueId()}>
                                <Link to={`/products/${item._id}`}>
                                    <img src={item?.additionalData} alt="product" className="img_custom" />
                                    <div className="about">
                                        <h2> {item?._id?.toUpperCase()} </h2>
                                    </div>
                                </Link>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>

        </section>
    );
};

export default ProductSlider;
