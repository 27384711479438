import React, { useState } from "react";
import "./ResetPassword.css"
import { useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { userResetPassword } from "../../redux-toolkit/slices/customerSlice/authSlice";
import { toast } from "react-toastify";



const ResetPassword = (props) => {

  const dispatch = useDispatch();
  const { token } = useParams();
  const navigate = useNavigate()

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loader, setLoader] = useState(false);

  const resetPasswordSubmit = (e) => {
    setLoader(true)
    e.preventDefault();
    const myForm = { password, confirmPassword };
    dispatch(userResetPassword({ token, myForm })).then(resp => {
      if (resp.payload?.data?.success) {
        setLoader(false)
        toast.success(resp.payload.data.message)
        navigate("/login")
      } else {
        setLoader(false)
        toast.error(resp.payload)
      }
    })
  };


  return (
    <div className="resetPasswordContainer-">
      <div className="container">
        <div className="titlebox">
          <h3 className="txth3">Update your password</h3>
        </div>
        <form className="frmbox" onSubmit={resetPasswordSubmit}>
          <div className="mb-4">
            <input type="password" className="form-control" placeholder="Password"
              required
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="mb-4">    <input type="password" className="form-control" placeholder="Confirm Password"
            required
            name="confirmPassword"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          /></div>

          <div className="btnbx">
            {loader ? <p>In Process...</p> : <>
              <button type="submit" className="btn" value="Send">Submit</button>
              <div className="ca"><Link to="/login" />Cancel</div>
            </>}
          </div>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
