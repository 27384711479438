import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { myOrders as fetchMyOrders } from '../../../actions/orderAction';
// import { loadUser, updatePassword } from '../../../actions/userAction';
// import moment from 'moment';
import { useAlert } from 'react-alert';
// import { clearErrors } from '../../../actions/productAction';
// import { UPDATE_PASSWORD_RESET } from '../../../constants/userConstants';
import { useNavigate } from 'react-router';
import { useViewport } from '../../../hooks/useViewPort';
import AccordionWrapper from '../../../reusable-component/accordion/AccordionWrapper';
import AccordionItem from '../../../reusable-component/accordion/AccordionItem';
import './CustomerDashboard.scss';
import ChangePassword from './ChangePassword';
import UserAddress from './UserAddress';
import Profile from './Profile';
import OrderHistory from './OrderHistory';

function CustomerDashboard() {
  const dispatch = useDispatch()
  const alert = useAlert();
  const navigate = useNavigate();
  const { isMobileView } = useViewport();
  const { user, cartData, userAuth } = useSelector((state) => state);

  useEffect(() => {
    dispatch(fetchMyOrders());
  }, []);


  useEffect(() => {
    if (!userAuth?.isAuthenticated) {
      navigate("/login")
    }
  }, [dispatch, alert, navigate, user]);

  // const { myOrders } = useSelector(state => state);
  // const orderList = myOrders?.orders;

  return (
    <div className="dashboard-wrapper">
      <AccordionWrapper className={isMobileView ? 'vertical' : 'horizontal'}>
        {/* order history */}
        <OrderHistory />

        {/* change password */}
        <ChangePassword />

        {/* user address */}
        <UserAddress />

        {/* user profile  */}
        <Profile />
      </AccordionWrapper>
    </div>
  );
}

export default CustomerDashboard;
