import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { adminOrderService } from "../../services/adminService/adminOrderService";

const initialState = {
    orders: {},
    orderDeatils: {},
    loading: false
};



export const getAllOrders = createAsyncThunk(
    "aOrder/getAllOrders",
    async (arg, thunkAPI) => {
        try {
            const response = await adminOrderService.getAllOrders();
            return response;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);


export const getOrderById = createAsyncThunk(
    "aOrder/getOrderById",
    async (arg, thunkAPI) => {
        try {
            // console.log(arg)
            const response = await adminOrderService.getOrderById(arg);
            return response;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);


export const updateOrder = createAsyncThunk(
    "aOrder/updateOrder",
    async (arg, thunkAPI) => {
        try {
            const response = await adminOrderService.updateOrder(arg);
            return response;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);


export const deleteOrder = createAsyncThunk(
    "aOrder/deleteOrder",
    async (id, thunkAPI) => {
        try {
            const response = await adminOrderService.deleteOrder(id);
            return response;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);


export const adminOrderSlice = createSlice({
    name: 'aOrder',
    initialState,
    extraReducers: (builder) => {
        builder
            // get all products
            .addCase(getAllOrders.pending, (state) => {
                state.orders = {}
            })
            .addCase(getAllOrders.fulfilled, (state, action) => {
                state.orders = action.payload.data
            })
            .addCase(getAllOrders.rejected, (state, action) => {
                state.orders = {}
            })

            // order data by id
            .addCase(getOrderById.pending, (state) => {
                state.orderDeatils = {}
                state.loading = true

            })
            .addCase(getOrderById.fulfilled, (state, action) => {
                state.orderDeatils = action.payload.data
                state.loading = false
            })
            .addCase(getOrderById.rejected, (state, action) => {
                state.orderDeatils = {}
                state.loading = false
            })
    },
})

// export const { clearProductDetails } = adminProductSlice.actions
export const adminOrderSliceReducer = adminOrderSlice.reducer
