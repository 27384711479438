import React from 'react'

function ExchangeRefund() {
	return (
		<div className="allsrvspeg prcypolypeg breadcrumbs-wrapper">
			<div className="container text-align-justify">
				<div className="titlbox">
					<h3 className="txth3">EXCHANGE AND RETURNS</h3></div>

				<p> Customers can exchange their order for a different product or avail the option of Online Store Credit for the same amount (minus shipping costs),</p>

				<p className="txtmdiam">* If an incorrect size or product has been delivered to you i.e. the order does not match the item in the order confirmation e-mail.</p>
				<p className="txtmdiam">* If the product you receive has a genuine quality/manufacturing defect, please photograph this as soon as you have opened the packaging. </p>

				<p className="txtmdiam"> If your order meets our <span className="text-uppercase font-weight-bold">return eligibility criteria</span> mentioned above, please contact us within 5 working days of delivery with the following information: Order Number, Delivery Address and Specify the reason for return and in case of a defective or incorrect product, please send us an image of the item.</p>


				<p className="txtmdiam"> Please ensure that the product(s) being returned to us are unused, unworn and the original tags on the product(s) are intact. </p>

				<p className="txtmdiam"> You may contact us via e-mail at <a href="mailto:mcaprintwala@gmail.com">mcaprintwala@gmail.com	</a> or call us on <a href="tel:+91-8923032312">+91 8923032312</a> from Monday to Saturday, between 10:30 AM – 6:00 PM (IST). </p>
				<p>We will look into the issue and respond to you within 48 working hours. </p>

			</div>
		</div>
	)
}

export default ExchangeRefund


