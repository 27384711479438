import React from 'react'

function PrivacyPolicy() {
      return (
            <div className="allsrvspeg prcypolypeg breadcrumbs-wrapper">
                  <div className="container text-align-justify">
                        <div className="titlbox">
                              <h3 className="txth3">Privacy policy</h3></div>
                        <p className="txtmdiam">Thank you for choosing to be a part of our community. We are committed to protect your personal information and right to privacy. When you use any of our services, we appreciate that you are trusting us with your personal information. We take your privacy very seriously. In this privacy notice, we seek to explain to you in the clearest way possible what information we collect, how we use it and what rights you have in relation to it. </p>

                        <h4 className="txth4">PERSONAL DATA</h4>
                        <p className="txtmdiam">We take responsibility for all the personal data (such as your name, address, email address, phone number, and date of birth) that you share with us, that we obtain when you subscribe to our newsletter or when you create a personal profile.</p>

                        <h4 className="txth4">WHAT INFORMATION DO WE COLLECT?
                        </h4>
                        <p className="txtmdiam">We collect information from you when you register on our site, place an order or subscribe to our newsletter. When ordering or registering on our site, as appropriate, you may be asked to enter your: name, e-mail address, mailing address, phone number, credit card information or date of birth. </p>

                        <h4 className="txth4">WHAT DO WE USE YOUR INFORMATION FOR?
                        </h4>
                        <p className="txtmdiam">Any of the information we collect from you may be used in one of the following ways:

                              <p className="txtmdiam"> To personalize your experience (your information helps us to better respond to your individual needs) To improve our website (we continually strive to improve our website offerings based on the information and feedback we receive from you)</p>

                              <p className="txtmdiam">To improve customer service (your information helps us to more effectively respond to your customer service requests and support needs)</p>

                              <p className="txtmdiam">To process transactions Your information, whether public or private, will not be sold, exchanged, transferred, or given to any other company for any reason whatsoever, without your consent, other than for the express purpose of delivering the purchased product or service requested.</p>
                              <p className="txtmdiam">To administer a contest, promotion, survey or other site feature </p>
                        </p>
                        <h4 className="txth4">HOW DO WE PROTECT YOUR INFORMATION?
                        </h4>
                        <p className="txtmdiam">We implement a variety of security measures to maintain the safety of your personal information when you place an order or enter, submit, or access your personal information. We offer the use of a secure server. All supplied sensitive/credit information is transmitted via Secure Socket Layer (SSL) technology and then encrypted into our Payment gateway providers database only to be accessible by those authorized with special access rights to such systems, and are required to keep the information confidential. After a transaction, your private information (credit cards etc.) will not be kept on file for more than 30 days. </p>

                        <h4 className="txth4">WHAT INFORMATION DOES McaPrintWala SHARE WITH THIRD PARTIES?
                        </h4>
                        <p className="txtmdiam">McaPrintWala may share your personal information with third parties or affiliates of McaPrintWala who perform services on our behalf or process authorized transactions. The personal information we share with these companies to perform services on our behalf is protected via contractual agreements and cannot be shared. We do not sell your information to any third party nor do we disclose your personal information to unaffiliated third parties. </p>


                        <h4 className="txth4">WHAT ARE YOUR RIGHTS?
                        </h4>
                        <p className="txtmdiam">You have the right to request information about the personal data we hold on you. If your data is incorrect, incomplete or irrelevant, you can ask to have the information corrected or removed.</p>


                        <h4 className="txth4">COOKIES</h4>
                        <p className="txtmdiam">A cookie is a small text file that is saved to, and, during subsequent visits, retrieved from your computer or mobile device. McaPrintWala uses cookies to enhance and simplify your visit. We do not use cookies to store personal information or to disclose information to third parties. </p>


                        <p className="txtmdiam">There are two types of cookies: permanent and temporary (session cookies). Permanent cookies are stored as a file on your computer or mobile device for no longer than 12 months. Session cookies are stored temporarily and disappear when you close your browser session. </p>

                        <p className="txtmdiam">We use permanent cookies to store your choice of start page and to store your details. We use session cookies when you use the product filtration function and to check if you are logged in. You can easily erase cookies from your computer or mobile device using your browser. </p>


                        <h4 className="txth4">THIRD-PARTY COOKIES
                        </h4>
                        <p className="txtmdiam">We use third-party cookies to collect statistics in aggregate form in analysis tools such as Google Analytics and Core Metrics. The cookies used are both permanent and temporary cookies (session cookies). The permanent cookies are stored on your computer or mobile device for no longer than 24 months.</p>


                        <h4 className="txth4">ONLINE PRIVACY ONLY
                        </h4>
                        <p className="txtmdiam">This online privacy policy applies only to information collected through our website and not to information collected offline. </p>
                  </div>
            </div>

      )
}

export default PrivacyPolicy