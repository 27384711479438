import { useEffect, useRef, useState } from "react"
import { clearErrors } from "../actions/productAction"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router"
import { useAlert } from "react-alert"
import axios from "axios"
import API_URL from "../config"
import { toast } from "react-toastify"

function loadScript(src) {
    return new Promise((resolve) => {
        const script = document.createElement('script')
        script.src = src
        script.onload = () => {
            resolve(true)
        }
        script.onerror = () => {
            resolve(false)
        }
        document.body.appendChild(script)
    })
}

function RazorpayButton({ pay, closePayment }) {
    const orderInfo = JSON.parse(sessionStorage.getItem("orderInfo"));
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const alert = useAlert();

    const payBtn = useRef(null);


    const { shippingInfo } = useSelector((state) => state.cart);
    const { userAuth } = useSelector((state) => state);
    const { userLoginData } = userAuth
    const { error } = useSelector((state) => state.newOrder);
    const { cartData } = useSelector((state) => state);

    const [isOpen, setIsOpen] = useState(false)


    const order = {
        shippingInfo,
        orderItems: cartData.cart,
        itemsPrice: orderInfo.subtotal,
        taxPrice: orderInfo.tax,
        shippingPrice: orderInfo.shippingCharges,
        totalPrice: orderInfo.totalPrice,
        currency: "INR",
        payment_method_types: "payu",
        description: "Ecomm MAHI",
        user: { email: userLoginData?.email },
        customer: userLoginData._id,

    };

    useEffect(() => {
        if (pay) {
            displayRazorpay();
        }
    }, [pay])

    // console.log(order)
    useEffect(() => {
        if (error) {
            alert.error(error);
            dispatch(clearErrors());
        }
        return () => {
            setIsOpen(false)
        }
    }, []);


    const makePayment = () => {
        setIsOpen(true)
    }

    async function displayRazorpay() {

        const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js')

        if (!res) {
            alert('Razropay failed to load!!')
            return
        }

        // const data = await fetch('http://localhost:5000/test/pg/create-order', { method: 'POST' }, { method: 'POST' }).then((t) =>
        //     t.json()
        // )
        const authToken = localStorage.getItem("authToken")
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${authToken}`
            },
        };
        const data = await axios.post(API_URL.createOrder, order, config).then((t) =>
            t.data
        ).catch(err => {
            if (err.response.status !== 200 && err.response.status !== 201) {
                toast.error(err.response.data.message || 'something went wrong, please try again');
                closePayment();
            }
        })
        const paymentData = data?.paymentData;

        if (paymentData) {
            const options = {
                "key": paymentData.pg_key_id, // Enter the Key ID generated from the Dashboard
                "amount": paymentData.totalPrice, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
                "currency": paymentData.currency,
                "name": "MCA Print Wala",
                "description": "Test Transaction",
                "image": "https://example.com/your_logo",
                "order_id": paymentData.payment_id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
                "callback_url": API_URL.verifyPayment,
                "notes": {
                    "address": "Razorpay Corporate Office"
                },
                "theme": {
                    "color": "#3399cc"
                },
                "modal": {
                    "ondismiss": function () {
                        closePayment();
                    }
                }
            };
            const paymentObject = new window.Razorpay(options);
            paymentObject.open();
        }


    }



    return (
        <div className="App">
            {/* <header className="App-header">
               
                <button
                    onClick={displayRazorpay}
                    className="primary-cta"
                >
                    Pay now
                </button>
            </header> */}
        </div>
    );
}

export default RazorpayButton;