import {
  ADD_TO_CART,
  REMOVE_CART_ITEM,
  GET_CART_ITEM,
  SAVE_SHIPPING_INFO,
  ADD_TO_WISHLIST,
  REMOVE_WISHLIST_ITEM
} from "../constants/cartConstants";

export const cartReducer = (
  state = { cartItems: [], shippingInfo: {}, wishList: [] },
  action
) => {
  switch (action.type) {
    case ADD_TO_CART:

      const item = action.payload;
      const isItemExist = state.cartItems.find(
        (i) => i.product === item.products[0].product
      );
      console.log("isItemExist", isItemExist)
      console.log("item", item)
      console.log("state.cartItems", state.cartItems)
      let newCartItems = []

      if (isItemExist) {
        state.cartItems.map((i) => {
          if (i.product === item.products[0].product) {
            newCartItems.push({ ...i, ...item.products[0] })
          } else {
            newCartItems.push({ ...i })
          }
        });
        return {
          ...state,
          cartItems: newCartItems,
        };
      } else {
        return {
          ...state,
          cartItems: [...state.cartItems, item.products[0]],
        };
      }

    case REMOVE_CART_ITEM:

      return {
        ...state,
        cartItems: state.cartItems.filter((i) => i._id !== action.payload),
      };

    case GET_CART_ITEM:
      console.log("GET_CART_ITEM", action.payload)
      return {
        ...state,
        cartItems: action.payload,
      };

    case SAVE_SHIPPING_INFO:
      return {
        ...state,
        shippingInfo: action.payload,
      };

    case ADD_TO_WISHLIST:
      const wishListItem = action.payload;

      const isWishListItemExistd = state.wishList.find(
        (i) => i.product === wishListItem.product
      );

      if (isWishListItemExistd) {
        return {
          ...state,
          wishList: state.wishList.map((i) =>
            i.product === isWishListItemExistd.product ? wishListItem : i
          ),
        };
      } else {
        return {
          ...state,
          wishList: [...state.wishList, wishListItem],
        };
      }

    case REMOVE_WISHLIST_ITEM:
      return {
        ...state,
        wishList: state.wishList.filter((i) => i.product !== action.payload),
      };

    default:
      return state;
  }
};
