import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { wishlistService } from "../../services/customerService/wishlistService";


const initialState = {
    wishlist: [],
    loading: false
};



export const addWishlist = createAsyncThunk(
    "wishlist/addWishlist",
    async (obj, thunkAPI) => {
        try {
            const response = await wishlistService.addWishlist(obj);
            return response;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);
export const getWishlist = createAsyncThunk(
    "wishlist/getWishlist",
    async (obj, thunkAPI) => {
        try {
            const response = await wishlistService.getWishlist();
            return response;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);
export const deleteWishlistItem = createAsyncThunk(
    "wishlist/deleteWishlistItem",
    async (obj, thunkAPI) => {
        try {
            const response = await wishlistService.deleteWishlistItem(obj);
            return response;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);




export const wishlistSlice = createSlice({
    name: 'wishlistSlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getWishlist.pending, (state) => {
                state.wishlist = []
                state.loading = true
            })
            .addCase(getWishlist.fulfilled, (state, action) => {
                state.wishlist = action.payload.data
                state.loading = false
            })
            .addCase(getWishlist.rejected, (state) => {
                state.wishlist = []
                state.loading = true
            })

    }
})


export const wishlistSliceReducer = wishlistSlice.reducer