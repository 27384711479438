import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import './SearchBar.scss';
import API_URL from '../../../../config';
const Searchbar = () => {
    const navigate = useNavigate();
    const [items, setItems] = useState([])

    // console.log(API_URL.searchQuery);
    const handleOnSearch = async (keyword, results) => {
        await fetch(`${API_URL.searchQuery}?query=${keyword}`)
            .then(response => response.json())
            .then(data => setItems(data?.result))
    }
    const handleOnSelect = (item) => {
            navigate(`/products/${item.category}`)
        
    }

    const formatResult = (item) => {
        return (
            <>
                <img src={item.image} alt={item.name} style={{ display: 'inline-block', height: '50px' }} />
                <span style={{ display: 'block', textAlign: 'left' }}>{item.name}</span>
            </>
        )
    }

    return (
        <div className="search-container">
            <ReactSearchAutocomplete
                items={items}
                onSearch={handleOnSearch}
                onSelect={handleOnSelect}
                formatResult={formatResult}
                maxResults={5}
                placeholder={'Search here to buy your custom gift'}
                showNoResults={true}
                showNoResultsText={'Nothing Found'}
                styling={{
                    backgroundColor: 'var(--light)',
                    height: '30px'
                }}
            />
        </div>
    );
};

export default Searchbar;
