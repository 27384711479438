import { Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import Yup from '../../../reusable-component/formik/Yup';
// import { clearErrors, login } from "../../../actions/userAction";
import { useDispatch, useSelector } from 'react-redux';
import FormikController from '../../../reusable-component/formik/FormikController';
import { userLogin } from '../../../redux-toolkit/slices/customerSlice/authSlice';
import { toast } from 'react-toastify';
import { isValidJWT } from '../../../Utilites/TokenValidation';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { fetchCartData } from '../../../redux-toolkit/slices/cartSlice';


function SignInComponent() {

  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [loading, setloading] = useState(false)
  const { userAuth } = useSelector(state => state)
  const { userToken } = userAuth

  useEffect(() => {
    if (isValidJWT() === true && userToken != "") {
      navigate("/")
    }
  }, [userToken])


  const loginIntialState = {
    email: "",
    password: "",
  }


  const loginValidationSchema = Yup.object({
    email: Yup.string()
      .trim()
      .email("Invalid email")
      .required("Required")
      .nullable(),
    password: Yup.string()
      .required("Password Required")
  });


  const loginSubmit = (value) => {
    setloading(true)
    dispatch(userLogin({ ...value })).then(resp => {

      if (resp.payload?.data?.success) {
        dispatch(fetchCartData())
        navigate("/")
      } else {
        toast.error(resp.payload)
      }
      setloading(false)
    }
    ).catch(err => {
      toast.error("Something went wrong")
      setloading(false)
    })
  };




  return (
    <Formik
      initialValues={loginIntialState}
      validationSchema={loginValidationSchema}
      onSubmit={(v) => loginSubmit(v)}>
      {(formik) => (
        <Form className="sign-in-form">
          <h2 className="title">Sign in</h2>
          <div className="input-field">
            <i className="fas fa-user"></i>
            <FormikController
              control="input"
              name="email"
              placeholder="Email"
            />
          </div>
          <div className="input-field">
            <i className="fas fa-lock"></i>
            <FormikController
              type="password"
              control="input"
              placeholder="Password"
              name="password"
            />
          </div>
          <button type="submit" className="btn solid" disabled={loading}> {loading ? "Loading..." : "Sign In"}</button>

          <Link to="/forgot-password">Forgot Password</Link>
          <p className="social-text">Or Sign in with social platforms</p>
          <div className="social-media">
            <a href="https://www.instagram.com/mcaprintwala" className="social-icon" aria-label="Instagram" >
              <i className="fab fa-instagram"></i>
            </a>
          </div>
        </Form>)}
    </Formik>
  )
}

export default SignInComponent