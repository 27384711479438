import React from 'react';
import { Link } from 'react-router-dom';
import { dirImages } from '../../../../Utilites/GetImage';
import './HeaderLogo.scss';
const HeaderLogo = () => {
    return (
        <div className="header-logo">
            <Link to="/">
                <img src={dirImages["mcaprintwala.png"]} alt="" />
            </Link>
        </div>
    );
};

export default HeaderLogo;
