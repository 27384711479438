import React, { useEffect, useRef } from "react";
import { dirBanner } from "../../Utilites/GetImage";
import { useDispatch, useSelector } from "react-redux";
import { getProductCategory } from "../../actions/productAction";
import Banner from './Banner/Banner';
import ProductSlider from './ProductSlider/ProductSlider';
import DealSlider from './DealSlider/DealSlider';
import ProductData from '../../assets/mocks/product.json';
import './Home.scss';


// console.log("ProductData", ProductData.products)

const Home = () => {
  const scrollRef = useRef(null);
  const dispatch = useDispatch();
  const { productCategory } = useSelector((state) => state);

  useEffect(() => {
    dispatch(getProductCategory());
  }, []);

  return (
    <React.Fragment>
      <div className="container" ref={scrollRef}>
        <Banner
          banners={[dirBanner['banner1.png'], dirBanner['banner2.png'], dirBanner['banner1.png']]}
        />
        <ProductSlider
          heading={"Top Categories"}
          productData={productCategory}
        />
        {/* <DealSlider
          title={"Most Selling Products --"}
          tagline={"Based on Your Activity"}
          productData={productCategory}
        /> */}
        <div className="offer-banner">
          <img draggable="false" src={dirBanner['banner2.png']} alt="offer banner" />
        </div>

        <div className="offer-banner">
          <img draggable="false" src={dirBanner['banner3.png']} alt="offer banner" />
        </div>
        {/* <Banner
            banners={[dirBanner['banner1.png'], dirBanner['banner2.png'], dirBanner['banner1.png']]}
        /> */}
        <ProductSlider
          heading={"Top Selection"}
          productData={productCategory}
        />
      </div>
    </React.Fragment>
  );
};

export default Home;
