import { axiosInstance } from "../../Utilites/Axios";
import API_URL from "../../config";

const getUserAddress = () => {
    return axiosInstance.get(API_URL.address);
};

// const addToCart = (obj) => {
//     return axiosInstance.post(API_URL.cart, obj);
// };

// const updateCart = (obj) => {
//     return axiosInstance.put(API_URL.cart, obj);
// };

// const removeCartItem = (obj) => {
//     return axiosInstance.post(API_URL.deleteCartItem, obj);
// };


const userService = {
    getUserAddress
};

export default userService;