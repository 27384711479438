import React, { Fragment, useState } from "react";
// import "./Shipping.css";
// import { useSelector, useDispatch } from "react-redux";
// import { saveShippingInfo } from "../../actions/cartAction";
// import MetaData from "../layout/MetaData";
// import PinDropIcon from "@material-ui/icons/PinDrop";
// import HomeIcon from "@material-ui/icons/Home";
// import LocationCityIcon from "@material-ui/icons/LocationCity";
// import PublicIcon from "@material-ui/icons/Public";
// import PhoneIcon from "@material-ui/icons/Phone";
// import TransferWithinAStationIcon from "@material-ui/icons/TransferWithinAStation";
// import { Country, State } from "country-state-city";
// import { useAlert } from "react-alert";
// import CheckoutSteps from "../Cart/CheckoutSteps";

const Shipping = () => {
 
  return (
    <Fragment>

<div class="shopngpeg">
    <div class="container">
      <div class="titlbox">
        <h3 class="txth3">Shipping policy</h3></div>
        <p class="txtmdiam">On dispatch of your order you will be sent a shipping confirmation email with your carrier information and tracking number, be sure to check Junk/Spam folder as well.</p>
        <p class="txtmdiam">Your order may be required to pay customs and import duties upon arrival of the goods into your country. Baroque is not responsible for any taxes or duties the customs office may charge you, and payment is necessary to release your order from customs.</p>
    </div>
  </div>
    
    </Fragment>
  );
};

export default Shipping;
