import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { clearErrors, getProduct } from '../../actions/productAction';
// import { addItemsToWishlist, removeItemsFromWishlist } from '../../actions/cartAction';
import { useDispatch, useSelector } from 'react-redux';
import { useAlert } from 'react-alert';
// import Loader from '../layout/Loader/Loader';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Rating from 'react-rating';
import { dirIcons } from '../../Utilites/GetImage';
import "./Products.scss";
import ReactPaginate from 'react-paginate';
import RangeSlider from './RangeSlider/RangeSlider';
import AccordionItem from '../../reusable-component/accordion/AccordionItem';
import AccordionWrapper from '../../reusable-component/accordion/AccordionWrapper';
import { useViewport } from '../../hooks/useViewPort';

function Products() {
    const dispatch = useDispatch();
    const params = useParams();
    const { isMobileView } = useViewport();
    // console.log("params",params)
    const alert = useAlert();
    const [showFilter, setShowFilter] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [priceRange, setPriceRange] = useState([0, 10000]);
    const [productSort, setProductSort] = useState({ "createdAt": 1 });

    const [fromPrice, setFromPrice] = useState(0);
    const [toPrice, setToPrice] = useState(1000);
    const paramCategory = params?.category;
    const {
        products,
        error,
        totalPages,
        filterData
    } = useSelector((state) => state.products);
    const {
    } = useSelector((state) => state.cart);
    // const keyword = match.params.keyword;
    const keyword = "";

    const handlePageClick = ({ selected: selectedPage }) => {
        // console.log("selectedPage", selectedPage)
        setCurrentPage(selectedPage + 1);
    };

    useEffect(() => {
        if (error) {
            alert.error(error);
            dispatch(clearErrors());
        }

        let filterCategory = ""
        filterCategory = paramCategory
        const price = [fromPrice, toPrice];

        if (filterCategory !== "") {
            // console.log("currentPage", currentPage)
            dispatch(getProduct(keyword, currentPage, price, filterCategory, 0, productSort));
        }


    }, [dispatch, keyword, currentPage, alert, params, productSort]);



    useEffect(() => {
        if (filterData) {
            setPriceRange([filterData?.min, filterData?.max])
        }
    }, [filterData])


    const priceFilterHandler = () => {
        const price = [fromPrice, toPrice];
        dispatch(getProduct(keyword, currentPage, price, params?.category, 0, productSort));
        // console.log("price", price)
    }

    const sortFilterHandler = (value) => {
        setProductSort(value)
        setCurrentPage(1)
    }

    const resetFilter = () => {
        setCurrentPage(1);
        setPriceRange([0, 10000]);
        setProductSort({ "createdAt": 1 });
    }

    const renderFilters = () => {
        return (
            <>
                <div className="product-filter-head">
                    <div className="close-btn-wrapper">
                        <button className="close-btn" title="filter" onClick={() => setShowFilter(false)}>
                            <i className="fa fa-close fa-2x"></i>
                        </button>
                    </div>
                    <h4 className="filter-label">FILTERS</h4>
                    <h4 className="filter-clear" onClick={() => resetFilter()}>CLEAR ALL</h4>
                </div>
                <div className="product-filter-body">
                    <div className="filter__types">
                        <AccordionWrapper className={isMobileView ? 'vertical' : ''}>
                            <AccordionItem
                                title={'Sort By'}
                                index={0}
                                description={
                                    <div className="filter__options">
                                        <div className="filter__option">
                                            <label className="radio-container">
                                                <input type="radio" name="sort" checked={productSort?.createdAt === 1} onClick={() => sortFilterHandler({ "createdAt": 1 })} />
                                                Newest First
                                                <span className="radioIndicator" />
                                            </label>
                                        </div>
                                        <div className="filter__option">
                                            <label className="radio-container">
                                                <input type="radio" name="sort" checked={productSort?.price === 1} onClick={() => sortFilterHandler({ "price": 1 })} />
                                                Price Low to High
                                                <span className="radioIndicator" />
                                            </label>
                                        </div>
                                        <div className="filter__option">
                                            <label className="radio-container">
                                                <input type="radio" name="sort" checked={productSort?.price === -1} onClick={() => sortFilterHandler({ "price": -1 })} />
                                                Price High to Low
                                                <span className="radioIndicator" />
                                            </label>
                                        </div>
                                        <div className="filter__option">
                                            <label className="radio-container">
                                                <input type="radio" name="sort" checked={productSort?.createdAt === -1} onClick={() => sortFilterHandler({ "createdAt": -1 })} />
                                                Oldest First
                                                <span className="radioIndicator" />
                                            </label>
                                        </div>
                                    </div>
                                }
                            />
                            <AccordionItem
                                title={'Price'}
                                index={1}
                                description={
                                    <div>
                                        <RangeSlider
                                            min={priceRange[0]}
                                            max={priceRange[1]}
                                            onChange={({ min, max }) => { setFromPrice(min); setToPrice(max); }}
                                        />
                                        <button className="btn btn-price-filter" onClick={priceFilterHandler}>Go</button>
                                    </div>


                                }
                            />
                            {/* <AccordionItem
                                title={'Size'}
                                index={2}
                                description={
                                    <div className="filter__options">
                                        <div className="filter__option">
                                            <label className="checkbox-container">
                                                <input type="checkbox" defaultChecked="checked" />
                                                S
                                                <span className="checkmark" />
                                            </label>
                                        </div>
                                        <div className="filter__option">
                                            <label className="checkbox-container">
                                                <input type="checkbox" />
                                                M
                                                <span className="checkmark" />
                                            </label>
                                        </div>
                                        <div className="filter__option">
                                            <label className="checkbox-container">
                                                <input type="checkbox" />
                                                L
                                                <span className="checkmark" />
                                            </label>
                                        </div>
                                        <div className="filter__option">
                                            <label className="checkbox-container">
                                                <input type="checkbox" />
                                                XL
                                                <span className="checkmark" />
                                            </label>
                                        </div>
                                        <div className="filter__option">
                                            <label className="checkbox-container">
                                                <input type="checkbox" />
                                                XXL
                                                <span className="checkmark" />
                                            </label>
                                        </div>
                                    </div>
                                }
                            />
                            <AccordionItem
                                title={'Customer Ratings'}
                                index={3}
                                description={
                                    <div className="filter__options">
                                        <div className="filter__option">
                                            <label className="checkbox-container">
                                                <input type="checkbox" />
                                                4★ & above
                                                <span className="checkmark" />
                                            </label>
                                        </div>
                                        <div className="filter__option">
                                            <label className="checkbox-container">
                                                <input type="checkbox" />
                                                3★ & above
                                                <span className="checkmark" />
                                            </label>
                                        </div>
                                        <div className="filter__option">
                                            <label className="checkbox-container">
                                                <input type="checkbox" />
                                                2★ & above
                                                <span className="checkmark" />
                                            </label>
                                        </div>
                                        <div className="filter__option">
                                            <label className="checkbox-container">
                                                <input type="checkbox" />
                                                1★ & above
                                                <span className="checkmark" />
                                            </label>
                                        </div>
                                    </div>
                                }
                            />
                            <AccordionItem
                                title={'Brand'}
                                index={4}
                                description={
                                    <div className="filter__options">
                                        <div className="filter__option">
                                            <label className="checkbox-container">
                                                <input type="checkbox" defaultChecked="checked" />
                                                Adidas
                                                <span className="checkmark" />
                                            </label>
                                        </div>
                                        <div className="filter__option">
                                            <label className="checkbox-container">
                                                <input type="checkbox" />
                                                Lacoste
                                                <span className="checkmark" />
                                            </label>
                                        </div>
                                        <div className="filter__option">
                                            <label className="checkbox-container">
                                                <input type="checkbox" />
                                                Monte Carlo
                                                <span className="checkmark" />
                                            </label>
                                        </div>
                                        <div className="filter__option">
                                            <label className="checkbox-container">
                                                <input type="checkbox" />
                                                Reebok
                                                <span className="checkmark" />
                                            </label>
                                        </div>
                                    </div>
                                }
                            /> */}
                        </AccordionWrapper>
                    </div>
                </div>
            </>
        )
    }

    const renderProducts = () => {
        if (products && products.length) {
            return (
                <div className="product-results-container">
                    {products.map(product => (
                        <div className="product" key={product?._id}>
                            <Link to={`/product-details/${product?._id}`}>
                                {/* <div
                                    className={`heart ${isActiveWishList(product?._id)}`}
                                    onClick={(e) => addRemoveItemFromWishlist(e, product?._id)} >
                                    <i className="fa fa-heart" />
                                </div> */}
                                <LazyLoadImage
                                    src={product?.image}
                                    alt={product?.name}
                                    className="mw-100 flpimg01 tnp-product-img"
                                    effect="blur"
                                    wrapperProps={{
                                        style: { transitionDelay: "1s" },
                                    }}
                                />
                                <div className="rating row">
                                    <Rating
                                        readonly={true}
                                        initialRating={4.5}
                                        emptySymbol="fa fa-star-o"
                                        fullSymbol="fa fa-star"
                                        fractions={2}
                                    />
                                    <h5>(2)</h5>
                                </div>
                                <h5 className="name">{product?.name}</h5>
                                <h4>INR {product?.price}</h4>
                                <span className="text-danger">{product?.Stock < 1 && 'Sold Out'}</span>
                            </Link>
                        </div>
                    ))}
                </div>
            )
        }

        return <h3>Loading...</h3>;
    }

    return (
        <div className="container">
            <div className="breadcrumbs-wrapper">
                <div className="breadcrumbs-bg">
                    <ul className="breadcrumbs">
                        <li>
                            <Link to="/" className="fas fa-home"></Link>
                        </li>
                        <li>{paramCategory}</li>
                    </ul>
                </div>
            </div>
            <div className="product-wrapper">
                <div className={`product-filter ${showFilter ? 'visible' : ''}`}>
                    {renderFilters()}
                </div>
                <div className="product-results">
                    {renderProducts()}
                    <div className="pagination">
                        <ReactPaginate
                            previousLabel={"←"}
                            nextLabel={"→"}
                            pageCount={totalPages}
                            onPageChange={handlePageClick}
                            containerClassName={"pagination"}
                            previousLinkClassName={"pagination__link"}
                            nextLinkClassName={"pagination__link"}
                            disabledClassName={"pagination__link--disabled"}
                            activeClassName={"pagination__link--active"}
                        />
                    </div>
                </div>
            </div>
            <div className="mobile-filter-btn">
                <div className="mobile-filter-btn-item" onClick={() => setShowFilter(true)}>
                    <img src={dirIcons['filter.svg']} alt="filter" />
                    <span>Filter By</span>
                </div>
            </div>
        </div>
    )
}

export default Products;
