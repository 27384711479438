const env = "prod";
let url, frontendURL, baseUrl;
if (env === "prod") {
  url = "https://ecomm-image-kdiqlyl27a-uc.a.run.app/api/v1";
  baseUrl = "https://ecomm-image-kdiqlyl27a-uc.a.run.app";


  frontendURL = ""
} else {
  url = "http://localhost:5000/api/v1";
  baseUrl = "http://localhost:5000";
  frontendURL = "http://localhost:3000";
}

const API_URL = {
  // USER 
  Login: `${url}/login`, // Post
  Register: `${url}/register`, // Post
  ForgotPasswrod: `${url}/password/forgot`, // Post
  ResetPassword: `${url}/password/reset`, // PUT
  Logout: `${url}/logout`, // GET
  loaduser: `${url}/me`, // GET
  updatePassword: `${url}/password/update`, // PUT
  getUserAddress: `${url}/user-address`,
  saveUserAddress: `${url}/save-user-address`,
  updateUserAddress: `${url}/update-user-address`,

  // wishlist 
  wishlist: `${url}/wishlist`,

  //Product
  GetProducts: `${url}/products`, // Get
  GetProductDetails: `${url}/product`, // Get
  GetProductsCategory: `${url}/get-products-category`, // Get
  searchQuery: `${url}/search-query/`, // Get
  // CreateProduct: `${url}/products`, // Post
  // UpdateProduct: `${url}/products/:id`, // Put


  // STRIPE
  Stripeapikey: `${url}/stripeapikey`,
  CreatePaymentIntent: `${url}/create-payment-intent`,
  updatePaymentStatus: `${url}/order/payment/status`,
  getPaymentStatus: `${url}/order/payment/status`,


  // PAYU
  createHash: `${url}/payment/createHash`,
  responseHandle: `${url}/order/pg/response`,

  //razorpay
  createOrder: `${url}/order/create-order`,
  verifyPayment: `${url}/payment/verify`,

  // Order
  createNewOrder: `${url}/order/new`,
  getOrderDetailById: `${url}/order`,
  myOrderList: `${url}/orders/me`,


  // cart - post/ get/ put/ delete
  cart: `${url}/cart`,
  deleteCartItem: `${url}/delete_cart_item`,

  //contact form submit query
  Contact: `${url}/contact`,

  // admin
  // product
  addNewProduct: `${url}/admin/product/new`,
  getAllProducts: `${url}/admin/products`,
  updateProduct: `${url}/admin/product`,
  productCount: `${url}/admin/product-count`,

  // order
  allOrders: `${url}/admin/orders`,
  updateOrder: `${url}/admin/order`,

  // users
  allUsers: `${url}/admin/users`,
  singleUser: `${url}/admin/user`,

};

export default API_URL;
export const FRONT_URL = frontendURL;

export const TIMEOUT = 1200; // 1200 seconds = 20 minutes
export const AUTH_TOKEN = "2044c5ea-d46f-4e9e-8b7a-2aa73ce44e69"

// COB PG credential