import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { adminProductService } from "../../services/adminService/adminProductService";



const initialState = {
    products: [],
    loading: false,
    errorMsg: "",

    productDetail: {
        loading: false,
        errorMsg: "",
        product: {}
    },
    totalProducts: {}

};




export const getAllProducts = createAsyncThunk(
    "aProduct/allProduct",
    async (arg, thunkAPI) => {
        try {
            const response = await adminProductService.getAllProducts();
            return response;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);


export const getProductDetailById = createAsyncThunk(
    "aProduct/getProductDetail",
    async (arg, thunkAPI) => {
        try {
            // console.log(arg)
            const response = await adminProductService.getProductById(arg);
            return response;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);


export const updateProduct = createAsyncThunk(
    "aProduct/updateProduct",
    async ({ id, data }, thunkAPI) => {
        try {
            const response = await adminProductService.updateProduct(id, data);
            return response;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);
export const deleteProduct = createAsyncThunk(
    "aProduct/deleteProduct",
    async (id, thunkAPI) => {
        try {
            const response = await adminProductService.deleteProduct(id);
            return response;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const addNewProduct = createAsyncThunk(
    "aProduct/addNewProduct",
    async (arg, thunkAPI) => {
        try {
            const response = await adminProductService.addNewProduct(arg);
            return response;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);


export const productCount = createAsyncThunk(
    "aProduct/productCount",
    async (arg, thunkAPI) => {
        try {
            const response = await adminProductService.productCount(arg);
            return response;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);



export const adminProductSlice = createSlice({
    name: 'aProduct',
    initialState,
    reducers: {
        clearProductDetails: (state) => {
            state.productDetail.loading = false
            state.productDetail.errorMsg = ""
            state.productDetail.product = {}
        }
    },
    extraReducers: (builder) => {
        builder
            // get all products
            .addCase(getAllProducts.pending, (state) => {
                state.products = []
                state.loading = true
                state.errorMsg = ""
            })
            .addCase(getAllProducts.fulfilled, (state, action) => {
                state.products = action.payload.data.products
                state.loading = false
            })
            .addCase(getAllProducts.rejected, (state, action) => {
                state.product = []
                state.loading = false
                state.errorMsg = action.payload
            })

            // get product detail by id
            .addCase(getProductDetailById.pending, (state) => {
                state.productDetail.loading = true
                state.productDetail.errorMsg = ""
                state.productDetail.product = {}
            })
            .addCase(getProductDetailById.fulfilled, (state, action) => {
                state.productDetail.loading = false
                state.productDetail.product = action.payload.data.product
            })
            .addCase(getProductDetailById.rejected, (state, action) => {
                state.productDetail.loading = false
                state.productDetail.errorMsg = action.payload
            })

            // product count
            .addCase(productCount.pending, (state) => {
                state.totalProducts = {}
            })
            .addCase(productCount.fulfilled, (state, action) => {

                state.totalProducts = action.payload.data
            })
            .addCase(productCount.rejected, (state, action) => {
                state.totalProducts = {}
            })
    },
})

export const { clearProductDetails } = adminProductSlice.actions
export const adminProductSliceReducer = adminProductSlice.reducer
