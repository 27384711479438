import React, { useState } from 'react'
import { Formik, Field, ErrorMessage, Form } from "formik";
import * as Yup from "yup";
import AccordionItem from '../../../reusable-component/accordion/AccordionItem'
import { useDispatch } from 'react-redux';
import { userUpdatePassword } from '../../../redux-toolkit/slices/customerSlice/authSlice';
import { toast } from 'react-toastify';


function ChangePassword() {


    const dispatch = useDispatch();

    const [loader, setLoader] = useState(false);



    const initialValues = {
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
    };

    const validationSchema = Yup.object().shape({
        oldPassword: Yup.string()
            .required('Old password is required'),
        newPassword: Yup.string()
            .min(8, 'Password must be at least 8 characters long')
            .required('New password is required'),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
            .required('Confirm password is required'),
    });

    const handleSubmit = (values) => {
        // console.log('Form data', values);
        dispatch(userUpdatePassword(values)).then(resp => {
            if (resp.payload?.data?.success) {
                setLoader(false)
                toast.success(resp.payload.data.message)
                //   navigate("/login")
            } else {
                setLoader(false)
                toast.error(resp.payload)
            }
        })
    };


    return (
        <AccordionItem
            title={'Change password'}
            index={1}
            description={
                <div className="chngpsbx">
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                        {formik => (
                            <Form className="updatePasswordForm">
                                <div className="form-group loginPassword">
                                    <label htmlFor="oldPassword">Old Password</label>
                                    <Field type="password" name="oldPassword" />
                                    <ErrorMessage name="oldPassword" component="div" />
                                </div>

                                <div className="form-group loginPassword">
                                    <label htmlFor="newPassword">New Password</label>
                                    <Field type="password" name="newPassword" />
                                    <ErrorMessage name="newPassword" component="div" />
                                </div>

                                <div className="form-group loginPassword">
                                    <label htmlFor="confirmPassword">Confirm Password</label>
                                    <Field type="password" name="confirmPassword" />
                                    <ErrorMessage name="confirmPassword" component="div" />
                                </div>
                                {loader ? <p>Processing...</p> : <button type="submit">Change Password</button>}
                            </Form>
                        )}
                    </Formik>
                </div>
            }
        >
        </AccordionItem>
    )
}

export default ChangePassword