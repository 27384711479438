import React from 'react';
import './HeaderStrip.scss';
const HeaderStrip = () => {
    return (
        <div className="header-strip">
            <p><i className="fa fa-envelope-open-text"> </i> mcaprintwala@gmail.com</p>
            <p>Mo: 9761100617</p>
        </div>
    );
};

export default HeaderStrip;
