import React, { useEffect } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import './Cart.scss';
import { useAlert } from 'react-alert';
import { fetchCartData, removeCartItem, updateCart } from '../../redux-toolkit/slices/cartSlice';

function Cart() {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const alert = useAlert()
  //userAuth userLoginData
  const { userAuth, cartData, isAuthenticated } = useSelector((state) => state);
  const { userLoginData } = userAuth
  // const { isAuthenticated } = user
  const cartItems = cartData.cart

  const userId = userLoginData?._id
  const fnFetchCart = (uid) => {
    dispatch(fetchCartData(uid))
  }

  useEffect(() => {
    fnFetchCart(userId)
  }, [userId])


  if (!isAuthenticated) {
    // navigate("/login");
    // alert.error("Please login to add product")
  }

  const increaseQuantity = async (obj) => {
    // TODO review the qyt / stock before increase
    let updateReqQyt = obj.productQyt + 1;
    await dispatch(updateCart({ ...obj, productQyt: updateReqQyt }))
    await fnFetchCart(userId)
  };


  const decreaseQuantity = async (obj) => {
    let updateReqQyt = obj.productQyt - 1;
    if (0 >= updateReqQyt) {
      await deleteCartItems({ cartId: obj?.cartId })
    } else {
      await dispatch(updateCart({ ...obj, productQyt: updateReqQyt }))
      await fnFetchCart(userId)
    }

  };


  const deleteCartItems = async (id) => {
    const deleteObj = { "cartId": id }
    await dispatch(removeCartItem(deleteObj))
    // console.log("chekkkkkk")
    fnFetchCart(userId)
  };



  const checkoutHandler = () => {
    navigate("/checkout");
  };


  console.log("cartData", cartData)

  const priceCalculte = (price, qyt) => price * qyt

  return (
    <React.Fragment>
      {cartItems.length === 0 ? (
        <div className="cart-empty-bag">
          <div className="container">
            <div className="titlebox">
              <h3 className="txth3">YOUR SHOPPING BAG IS EMPTY</h3>
              <p className="txth3">Be inspired and discover something new to add to your wardrobe.</p>
              <Link to="/" ><h3>View Categories</h3></Link>
            </div>
          </div>
        </div>

      ) : (
        <div className="cart">
          <div className="main-content-mobile">
            <div className="top">
              <p>Your order will Ship for FREE !!</p> <span><Link to="/">Continue Shopping <i className="fa fa-arrow-right" /></Link></span> </div>
            {/* Item Details Begin */}
            <div className="shopping-cart">
              <div className="item-details">
                <div className="heading">
                  <h5>Items Details</h5>
                  <h3>Shopping Cart</h3>
                  <h3>{cartItems?.length} Items</h3> </div>
                <div className="top-row desk">
                  <p>PRODUCT DETAILS</p>
                  <p>QUANTITY</p>
                  <p>TOTAL</p>
                </div>
                {cartItems &&
                  cartItems.map((item, i) => (
                    <div className="product" key={i}>
                      <div className="info">
                        <Link className="cart-image" to={`/product-details/${item?.product}`}>
                          <img src={item?.productDetails?.[0]?.image} alt="product" width={100} />
                        </Link>
                        <div className="cart-details">
                          <Link to={`/product-details/${item?.product}`}><h4>{item?.productDetails?.[0]?.name}</h4></Link>
                          <p><b>Price: </b>  INR {item?.productDetails?.[0]?.price}</p>
                          {item.productSize && item.productSize !== 'default' ? <p><b>Size:</b> {item.productSize}</p> : ''}
                          <p>
                            <b>Category: </b>
                            <span className="category">{item.productCategory}</span>
                          </p>

                          <p className="mobile price-col"><b>Total: </b>INR {priceCalculte(item?.productDetails?.[0]?.price, item.qty)}</p>
                          <div className="mobile">
                            <p className="quantity">
                              <button
                                className="btn-minus"
                                // disabled={}
                                onClick={() => decreaseQuantity({ productId: item.product, productQyt: item.qty, productCurrentStock: item.stock, productSize: item.productSize, productCategory: item.productCategory, cartId: item._id })}

                              >-</button>
                              <input className="quantity-field" type="text" value={item.qty} readOnly={true} />
                              <button
                                className="btn-plus"
                                onClick={() => increaseQuantity({ productId: item.product, productQyt: item.qty, productCurrentStock: item.stock, productSize: item.productSize, productCategory: item.productCategory, cartId: item._id })}
                              >+</button>
                            </p>
                          </div>
                          <button className="desk primary-cta" onClick={() => deleteCartItems(item._id)}>
                            <i className="fa fa-trash-alt" />  Remove
                          </button>
                        </div>
                        <button className="mobile remove" aria-label='button' onClick={() => deleteCartItems(item._id)}>
                          <i className="fa fa-trash-alt" />
                        </button>
                        <div className="desk">
                          <p className="quantity">
                            <button
                              className="btn-minus"
                              onClick={() => decreaseQuantity({ productId: item.product, productQyt: item.qty, productCurrentStock: item.stock, productSize: item.productSize, productCategory: item.productCategory, cartId: item._id })}
                            >-</button>
                            <input className="quantity-field" type="text" value={item.qty} readOnly={true} />
                            <button
                              className="btn-plus"
                              onClick={() => increaseQuantity({ productId: item.product, productQyt: item.qty, productCurrentStock: item.stock, productSize: item.productSize, productCategory: item.productCategory, cartId: item._id })}
                            >+</button>
                          </p>
                        </div>
                        <p className="desk price-col">INR {priceCalculte(item?.productDetails?.[0]?.price, item.qty)}</p>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
            {/* Item Details End */}
            <div className="order-summary order_summary_bg">
              <div className="title desk">
                <h3>Order Summary</h3>
              </div>
              <div className="items desk">
                <p>Items {cartItems.length}</p>
                <p> {`INR ${cartItems.reduce(
                  (acc, item) => acc + item.qty * item?.productDetails?.[0]?.price,
                  0
                )}`}
                </p>
              </div>
              <div className="promo-form">
                <p className="desk">PROMO CODE</p>
                <input type="text" placeholder="Have a Promo Code?" />
                <button>Apply</button>
              </div>
              <div className="receipt">
                <div className="row subtotal">
                  <p>Subtotal</p>
                  <p>{`INR ${cartItems.reduce(
                    (acc, item) => acc + item.qty * item?.productDetails?.[0]?.price,
                    0
                  )}`}
                  </p>
                </div>
                <div className="row shipping">
                  <p>Shipping &amp; Handling</p>
                  <p>FREE</p>
                </div>
                <div className="row mobile">
                  <p>Total</p>
                  <p>{`INR ${cartItems.reduce(
                    (acc, item) => acc + item.qty * item?.productDetails?.[0]?.price,
                    0
                  )}`}
                  </p>
                </div>
              </div>
              <div className="total desk">
                <p>Total Cost</p>
                <p>{`INR ${cartItems.reduce(
                  (acc, item) => acc + item.qty * item?.productDetails?.[0]?.price,
                  0
                )}`}
                </p>
              </div>
              <div className="cta">
                <button className="checkout primary-cta" onClick={checkoutHandler}> Proceed to Checkout </button>
                <Link to="/" className="primary-cta desk">Continue Shopping <i className="fa fa-long-arrow-alt-right" /></Link>
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  )
}

export default Cart