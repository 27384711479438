import React, { useRef, useEffect, useState } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { format } from 'date-fns';
// import { saveShippingInfo } from "../../actions/cartAction";
import { useAlert } from "react-alert";
import ProductInfoCard from './ProductInfoCard'
import { Link, useNavigate } from 'react-router-dom';
// import { CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
// import { axiosInstance } from '../../Utilites/Axios';
import { createOrder, clearErrors } from "../../actions/orderAction";
// import StripeContainer from '../../Stripe/StripeContainer';
// import PayuForm from '../../payu-pg/PayuForm';
import RazorpayButton from '../../razorpay/RazorpayButton';
import './payment.css';
import { removeCartItem } from '../../redux-toolkit/slices/cartSlice';



function Payment() {
  const [orderInfo, setOrderInfo] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const alert = useAlert();

  const payBtn = useRef(null);

  const { shippingInfo } = useSelector((state) => state.cart);
  const { cartData } = useSelector((state) => state);
  console.log('shippingInfo', shippingInfo);
  const { userAuth } = useSelector((state) => state);
  const { userLoginData } = userAuth;
  const [deliverDate, setDeliveryDate] = useState('');  //setting 3-4 days ahead of today for now.
  const [cartTotal, setCartTotal] = useState(null);

  const { error } = useSelector((state) => state.newOrder);

  const [isOpen, setIsOpen] = useState(false);


  console.log(cartData)
  useEffect(() => {
    const today = new Date();
    today.setDate(today.getDate() + 3);
    setDeliveryDate(format(today, 'dd MMM'));

    setCartTotal(cartData.cart.reduce((acc, obj) => acc + obj.productDetails[0].price, 0));

    setOrderInfo(JSON.parse(sessionStorage.getItem("orderInfo")));
  }, [cartData]);

  // useEffect(() => {
  //   setOrderSummary({
  //     shippingInfo: shippingDetails,
  //     orderItems: cartData.cart,
  //     itemsPrice: orderInfo.subtotal,
  //     taxPrice: orderInfo.tax,
  //     shippingPrice: orderInfo.shippingCharges,
  //     totalPrice: orderInfo.totalPrice,
  //     currency: "INR",
  //     payment_method_types: "razorpay",
  //     description: "ecomm mcawala",
  //     user: { email: userLoginData?.email },
  //     customer: userLoginData._id,

  //   })
  // }, [orderInfo, shippingDetails, cartData]);


  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
    return () => {
      setIsOpen(false)
    }
  }, []);


  const deleteCartItems = async (id) => {
    console.log(id);
    const deleteObj = { "cartId": id }
    await dispatch(removeCartItem(deleteObj));
  };

  const makePayment = () => {
    setIsOpen(true);
  }

  const handleClosePayment = () => {
    setIsOpen(false);
  }

  return (
    <div className='parent-div' style={{ margin: "5% 10%", backgroundColor: "#eef3fc", borderRadius: '20px', padding: "32px 48px 68px 48px" }}>
      <div className='heading-1'>
        <p style={{ fontSize: "24px", fontWeight: 500 }}>Secure checkout</p>
      </div>
      <div className='parent-2' style={{ display: 'flex', flexDirection: 'row', width: "100%", gap: '48px' }}>
        <div className='left-panel' style={{ width: '60%', display: 'flex', flexDirection: 'column', gap: '24px' }}>
          <div className='address' style={{ backgroundColor: 'white', borderRadius: '20px', padding: '16px 28px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <div className='left-address'>
              <div className='address-bar' style={{
                display: 'flex',
                gap: '10px',
                height: '22px',
                alignItems: 'center',
                marginBottom: '15px'
              }}>
                <span><i className='fas fa-map-marker-alt'></i></span>
                <p style={{ fontWeight: 500 }}>Address</p>
              </div>
              {shippingInfo && (
                <>
                  <div className='name' style={{ color: '#0d4bcd', fontWeight: 500, marginBottom: '12px' }}>
                    {shippingInfo.firstName} {shippingInfo.lastName}
                  </div>
                  <p className='adddress-1 m-0 f-12'>{shippingInfo.address}</p>
                  <p className='adddress-2 m-0 f-12'>{shippingInfo.city}</p>
                  <p className='adddress-3 m-0 f-12'>{shippingInfo.country}</p>
                  <p className='adddress-3 m-0 f-12'>{shippingInfo.pinCode}</p>
                  <p className='adddress-4 m-0 f-12'>Phone :- {shippingInfo.phoneNo}</p>
                  <p className='adddress-4 m-0 f-12'>Email :- {shippingInfo.email}</p>
                  <div className='edit-add' style={{ marginTop: '20px' }}>
                    <button className='c-btn'>Edit Address</button>
                  </div></>)
              }
            </div>

            <div className='right-address'>
              <button className='c-btn'>Choose another address</button>
            </div>
          </div>
          <div className='cart' style={{ marginTop: '20px' }}>
            <div className='parent-cart'>
              <div className='cart-heading' style={{ display: 'flex', flexDirection: "row", justifyContent: 'space-between', marginBottom: '15px' }}>
                <div className='cart-title'>
                  <p className='m-0' style={{ fontWeight: 500 }}>Your Cart <span style={{ color: '#aaaaaa', fontSize: '12px' }}>({cartData && cartData.cart.length} Items)</span></p>
                </div>
                <div className='add-wishlist'>
                  <button className='c-btn'>+ Add More</button>
                </div>
              </div>
              <div style={{ backgroundColor: 'white', borderRadius: '20px' }}>
                {cartData && cartData.cart &&
                  cartData.cart.map((order, i) => (
                    <div key={i} className='cart-item' style={{ padding: '16px 28px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                      <div className='cart-item-parent' style={{ display: 'flex', flexDirection: 'row', gap: '40px' }}>
                        <div className='img-boxx' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} >
                          <img className='img-f' style={{ width: '100px', height: '100px' }} src={order.productDetails[0].image} alt='product-img' />
                          {/* <button style={{ height: '22px', backgroundColor: 'transparent', color: '#000', textDecoration: 'none', border: 'none', padding: "4px", cursor: 'pointer' }} onClick={() => deleteCartItems(order.productDetails[0]._id)}>
                            <i className="fas fa-times"></i>
                          </button> */}
                        </div>
                        <div className='cart-details' style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                          <p className='m-0 f-14' style={{ color: '#0124c3' }}>{order?.productDetails[0]?.name}</p>
                          <p className='m-0 f-14' style={{ color: '#929292' }}>{order?.productDetails[0]?.description && order.productDetails[0]?.description[1] || ''}</p>
                          <div className='price-container f-14' style={{ display: 'flex', flexDirection: "row", gap: '20px' }}>

                            <p className='m-0 f-14'>₹ {order?.productDetails[0].price}</p>
                            <div className='discount' style={{ backgroundColor: "#f5e9d9", borderRadius: '20px', padding: " 3px 8px" }}>
                              <p className='m-0' style={{ color: "#ba6215", fontSize: '12px' }}>50% Off</p>
                            </div>
                          </div>
                          <p className='m-0 f-14' style={{ marginTop: "10px", color: "#31a256" }}><span><i className='fas fa-truck'></i></span> Est. Delivery By {deliverDate}</p>
                        </div>
                        <div className='cart-size-qty' style={{ textAlign: 'center' }}>
                          <p className='m-0 f-14' style={{ fontWeight: 400 }}>Quantity:</p>
                          <p className='m-0 f-14'>{order.qty}</p>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>

            </div>
          </div>
        </div>
        <div className='right-panel' style={{ width: '40%', backgroundColor: 'white', borderRadius: '20px', padding: '16px 28px', display: 'flex', flexDirection: 'column' }}>
          <p className='m-0' style={{ fontWeight: 500, marginBottom: "20px" }}>Order Summary</p>
          <div className='order-summary-1' style={{ display: 'flex', flexDirection: 'column', gap: '10px', borderBottom: '1px solid #e6e6e6', paddingBottom: '15px' }}>
            <div className='bag-total bag-style' >
              <p className='m-0 f-14'>Bag Total</p>
              <p className='m-0 f-14'>₹ {cartTotal}</p>
            </div>
            <div className='bag-discount bag-style'>
              <p className='m-0 f-14'>Bag Discout</p>
              <p className='m-0 f-14'>₹ 0</p>
            </div>
            <div className='delivery-fee bag-style'>
              <p className='m-0 f-14'>Delivery Fee</p>
              <p className='m-0 f-14'>₹ 0</p>
            </div>
            <div className='order-total bag-style'>
              <p className='m-0' style={{ color: "#018929", fontWeight: 500 }}>Order Total</p>
              <p className='m-0' style={{ color: "#018929", fontWeight: 500 }}>₹ {cartTotal}</p>
            </div>
          </div>
          <p className='m-0' style={{ fontWeight: 500, marginTop: "20px", marginBottom: '5px' }}>Payment Options</p>
          <div className="form-check mb">
            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" defaultChecked /><span style={{ fontSize: "14px" }}> UPI/ Wallet/ Debit Card/ Credit Card/ Net Banking</span>
          </div>
          <div style={{ marginTop: 'auto' }}>
            <button disabled={isOpen} onClick={() => makePayment()} style={{ backgroundColor: "#5386e4", color: 'white', textDecoration: 'none', borderRadius: "10px", width: "100%", border: 'none', padding: "15px", cursor: 'pointer' }}>Pay ₹ {cartTotal}</button>

          </div>
          {isOpen && <RazorpayButton pay={isOpen} closePayment={handleClosePayment} />}
        </div>
      </div>
    </div>
  )
}

export default Payment;