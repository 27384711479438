import { axiosInstance } from "../../../Utilites/Axios";
import API_URL from "../../../config";


const addWishlist = (data) => {
    return axiosInstance.post(API_URL.wishlist, data)
}

const getWishlist = () => {
    return axiosInstance.get(API_URL.wishlist)
}

const deleteWishlistItem = (data) => {
    return axiosInstance.post(API_URL.wishlist, data)
}


export const wishlistService = {
    addWishlist,
    getWishlist,
    deleteWishlistItem
}