import React, { useEffect, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import FormikController from '../../../reusable-component/formik/FormikController';
import { State, City } from "country-state-city";
import { convertToFormikSelectJson } from '../../../Utilites/convertToFormikSelectJson';
import { useDispatch, useSelector } from 'react-redux';
import { deleteUserAddress, getUserAddress, saveUserAddress, updateUserAddress } from '../../../redux-toolkit/slices/customerSlice/authSlice';
import { toast } from 'react-toastify';


const UserAddressForm = () => {

    const dispatch = useDispatch()
    const { userAuth } = useSelector(state => state)
    const { userAddress } = userAuth
    const [state, setState] = useState([])
    const [selectedState, setSelectedState] = useState("")
    const [city, setCity] = useState([])
    const [radioSelectAddr, setRadioSelectAddr] = useState({})
    const [isEdit, setIsEdit] = useState(false)
    const [updateAddrId, setUpdateAddrId] = useState("")



    useEffect(() => {
        dispatch(getUserAddress())
        const IndiaState = State.getStatesOfCountry("IN")
        const stateOption = convertToFormikSelectJson("isoCode", "name", IndiaState)
        setState(stateOption)
    }, [])


    useEffect(() => {
        if (selectedState !== '') {
            const cityData = City.getCitiesOfState("IN", selectedState)
            const cityOption = convertToFormikSelectJson("isoCode", "name", cityData)
            setCity(cityOption)
        }

        return () => {
            setIsEdit(false)
        }
    }, [selectedState])



    // Validation schema
    const validationSchema = Yup.object().shape({
        address: Yup.string().required('Address is required'),
        country: Yup.string().required('Country is required'),
        city: Yup.string().required('City is required'),
        state: Yup.string().required('State is required'),
        pinCode: Yup.string().required('Pincode is required'),
    });

    const initialValues = {
        address: radioSelectAddr?.address ?? '',
        pinCode: radioSelectAddr?.pinCode ?? '',
        country: radioSelectAddr?.country ?? 'IN',
        city: radioSelectAddr?.city ?? '',
        state: radioSelectAddr?.state ?? '',
    }

    const submitHandler = (values) => {
        if (isEdit) {
            dispatch(updateUserAddress({ id: updateAddrId, data: values }))
                .then(resp => {
                    if (resp.payload?.data) {
                        dispatch(getUserAddress())
                        toast.success("Address Update")
                    } else {
                        toast.error("Something went wrong")
                    }
                }).catch(err => toast.error("Something went wrong"))

        } else {
            dispatch(saveUserAddress(values)).then(resp => {
                if (resp.payload?.data) {
                    dispatch(getUserAddress())
                    toast.success("Address Saved")
                } else {
                    toast.error("Something went wrong")
                }
            }).catch(err => toast.error("Something went wrong"))
        }
        // console.log(values)
    }



    const deleteHandler = (id) => {
        dispatch(deleteUserAddress({ id: id }))
            .then(resp => {
                if (resp.payload?.data) {
                    dispatch(getUserAddress())
                    toast.success("Address Removed")
                } else {
                    toast.error("Something went wrong")
                }
            }).catch(err => toast.error("Something went wrong"))
    }


    return (
        <div>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={submitHandler}
                enableReinitialize={true}
            >
                {({ setFieldValue, values, errors }) => (
                    <Form className="address__form">
                        <div className="form-input">
                            <FormikController
                                control="textArea"
                                className="input-control"
                                label="Address"
                                name="address"
                                col={8}
                                row={8}
                            />
                        </div>
                        <div className="form-input">
                            <FormikController
                                control="input"
                                className="input-control"
                                label="Country"
                                name="country"
                                disabled={true}
                            />
                        </div>
                        <div className="form-input">
                            <FormikController
                                control="select"
                                className="input-control"
                                label="State"
                                name="state"
                                options={state}
                                onChange={
                                    (e) => {
                                        setFieldValue("state", e.target.value)
                                        setSelectedState(e.target.value)
                                    }
                                }
                            />
                        </div>
                        <div className="form-input">
                            <FormikController
                                control="select"
                                className="input-control"
                                label="City"
                                name="city"
                                options={city}
                            />
                        </div>
                        <div className="form-input">
                            <FormikController
                                control="input"
                                className="input-control"
                                label="Pin Code"
                                name="pinCode"
                            />
                        </div>
                        <div className="form-input">
                            <button type="submit">Submit</button>
                        </div>
                        <div className="form-input">
                            <a className='reset_form' href={() => false} onClick={() => {
                                setIsEdit(false)
                                setRadioSelectAddr({})

                            }}>Reset Form</a>
                        </div>
                    </Form>
                )}
            </Formik>

            <div className="savedAddress">
                {userAddress?.map(result => (
                    <div className="form-input" key={result._id}>
                        <input type='radio' name="userAddress" value={result._id} onChange={() => {
                            setRadioSelectAddr(result)
                            setIsEdit(true)
                            setUpdateAddrId(result._id)
                        }} />
                        <p>{result?.address}</p>
                        <p>{result?.city}</p>
                        <p>{result?.state}</p>
                        <p>{result?.pinCode}</p>
                        <p>{result?.country}</p>
                        <div>
                            <button onClick={() => deleteHandler(result._id)}>Delete</button>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default UserAddressForm;
