import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { adminUserService } from "../../services/adminService/adminUserService";


const initialState = {
    users: {},
    userDetails: {},
    loading: false
};



export const getAllUser = createAsyncThunk(
    "aUser/getAllUser",
    async (arg, thunkAPI) => {
        try {
            const response = await adminUserService.getAllUser();
            return response;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);


export const userDetail = createAsyncThunk(
    "aUser/userDetail",
    async (arg, thunkAPI) => {
        try {
            // console.log(arg)
            const response = await adminUserService.userDetail(arg);
            return response;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);


export const updateUser = createAsyncThunk(
    "aUser/updateUser",
    async (arg, thunkAPI) => {
        try {
            const response = await adminUserService.updateUser(arg);
            return response;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);


export const deleteUser = createAsyncThunk(
    "aUser/deleteUser",
    async (id, thunkAPI) => {
        try {
            const response = await adminUserService.deleteUser(id);
            return response;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);


export const adminUserSlice = createSlice({
    name: 'aUser',
    initialState,
    extraReducers: (builder) => {
        builder
            // get all products
            .addCase(getAllUser.pending, (state) => {
                state.users = {}
            })
            .addCase(getAllUser.fulfilled, (state, action) => {
                state.users = action.payload.data
            })
            .addCase(getAllUser.rejected, (state, action) => {
                state.users = {}
            })

            // order data by id
            .addCase(userDetail.pending, (state) => {
                state.userDetails = {}
                state.loading = true

            })
            .addCase(userDetail.fulfilled, (state, action) => {
                state.userDetails = action.payload.data

                state.loading = false
            })
            .addCase(userDetail.rejected, (state, action) => {
                state.userDetails = {}
                state.loading = false
            })
    },
})

// export const { clearProductDetails } = adminProductSlice.actions
export const adminUserSliceReducer = adminUserSlice.reducer
