
import { axiosInstance } from "../../../Utilites/Axios";
import API_URL from "../../../config";


const getAllUser = () => {
    return axiosInstance.get(API_URL.allUsers);
};


const updateUser = ({ userId, postData }) => {
    return axiosInstance.put(`${API_URL.singleUser}/${userId}`, postData)
}

const deleteUser = (id) => {
    return axiosInstance.delete(`${API_URL.singleUser}/${id}`)
}


const userDetail = (id) => {
    return axiosInstance.get(`${API_URL.singleUser}/${id}`)
}



export const adminUserService = {
    getAllUser,
    updateUser,
    deleteUser,
    userDetail
}