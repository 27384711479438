
import { axiosInstance } from "../../../Utilites/Axios";
import API_URL from "../../../config";


const getAllOrders = () => {
    return axiosInstance.get(API_URL.allOrders);
};


const updateOrder = ({ id, status }) => {
    console.log({ id, status })
    return axiosInstance.put(`${API_URL.updateOrder}/${id}`, { status })
}

const deleteOrder = (id) => {
    return axiosInstance.delete(`${API_URL.updateOrder}/${id}`)
}


const getOrderById = (id) => {
    return axiosInstance.get(`${API_URL.getOrderDetailById}/${id}`)
}



export const adminOrderService = {
    getAllOrders,
    getOrderById,
    updateOrder,
    deleteOrder
}