import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Home from './component/Home/Home';
import Header from './component/layout/Header/Header';
import Footer from './component/layout/Footer/Footer';
// import Loader from './component/layout/Loader/Loader';
import LoginComponent from './component/User/Login/LoginComponent';
// import RegisterComponent from './component/User/RegisterComponent';
// import ForgotPwdComponent from './component/User/ForgotPwdComponent';
import Products from './component/Product/Products';
import ProductDetails from './component/Product/ProductDetails/ProductDetails';
import Cart from './component/Cart/Cart';
// import TheBrand from './component/OtherComponent/TheBrand';
import PrivacyPolicy from './component/OtherComponent/PrivacyPolicy';
import TermCond from './component/OtherComponent/TermCond';
// import About from './component/layout/About/About';
import Contact from './component/layout/Contact/Contact';
import CheckoutSteps from './component/Cart/CheckoutSteps';
import Shipping from './component/Cart/Shipping';
import Payment from './component/Cart/Payment';
import ConfirmOrder from './component/Cart/ConfirmOrder';
import OrderSuccess from './component/Cart/OrderSuccess';
import WishList from './component/Product/WishList/WishList';
import CustomerDashboard from './component/User/CustomerDashboard/CustomerDashboard';
import ResetPassword from './component/User/ResetPassword';
// import LookBook from './component/layout/About/Lookbook';
import ExchangeRefund from './component/OtherComponent/ExchangeRefund';
// import { useSelector } from 'react-redux';
// import axios from 'axios';

// import ProtectedRoute from './component/Route/ProtectedRoute';
// import { Elements, ElementsConsumer } from "@stripe/react-stripe-js";
// import { loadStripe } from "@stripe/stripe-js";
// import store from './store';
// import { loadUser } from "./actions/userAction";
import NotFound from './component/layout/Not Found/NotFound';
// import API_URL from './config';

// import StripePayment from './component/Cart/StripePayment';
// import PaymentResponse from './Stripe/PaymentResponse';
// import GoToTop from './Utilites/GoToTop';
// import { dirImages } from './Utilites/GetImage';
// import PayuFail from './payu-pg/PayuFail';
// import PayuForm from './payu-pg/PayuForm';
// import PayuSuccess from './payu-pg/PayuSuccess';
// import CashFree from './cashfree/CashFree';
import RazorpayButton from './razorpay/RazorpayButton';
import Dashboard from './component/Admin/Dashboard';
import NewProduct from './component/Admin/NewProduct';
import ProductList from './component/Admin/ProductList';
import OrderList from './component/Admin/OrderList';
import ProcessOrder from './component/Admin/ProcessOrder';
import OrderUpdate from './component/Admin/OrderUpdate';
import UsersList from './component/Admin/UsersList';
import UpdateUser from './component/Admin/UpdateUser';
import ForgotPassword from './component/User/ForgotPassword';
import AuthorizedRoute from './protectedRoute/AuthorizedRoute';


function AllRoutes() {

  return (
    <div style={{display: 'flex', flexDirection:"column", justifyContent:"space-between"}}>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route exact path="/login" element={<LoginComponent />} />
        <Route exact path="/forgot-password" element={<ForgotPassword />} />
        <Route exact path="/reset-password/:token" element={<ResetPassword />} />
        <Route exact path="/products/:category" element={<Products />} />
        <Route exact path="/product-details/:product_id" element={<ProductDetails />} />
        <Route exact path="/cart" element={<Cart />} />

        {/* new url created*/}
        <Route exact path="/checkout" element={<CheckoutSteps />} /> {/*1 */}
        {/* <Route exact path="/shipping" element={<Shipping />} />  */}
        <Route exact path="/payment" element={<Payment />} />
        {/* <Route exact path="/payment-response" element={<PaymentResponse />} />  */}
        <Route exact path="/confirm-order/:order_id" element={<ConfirmOrder />} />

        <Route exact path="/customer-dashboard" element={<CustomerDashboard />} />
        <Route exact path="/order" element={<OrderSuccess />} />
        <Route exact path="/wishlist" element={<WishList />} />
        <Route exact path="/contact" element={<Contact />} />
        <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route exact path="/term-condition" element={<TermCond />} />
        <Route exact path="/exchange-refund" element={<ExchangeRefund />} />
        <Route exact path="/shipping" element={<Shipping />} />
        <Route exact path="/razorpay" element={<RazorpayButton />} />

        <Route exact path="/admin/dashboard" element={<Dashboard />} />
        <Route exact path="/admin/product/" element={<NewProduct />} />
        <Route exact path="/admin/product/edit/:id" element={<NewProduct />} />
        <Route exact path="/admin/products" element={<ProductList />} />
        <Route exact path="/admin/orders" element={<OrderList />} />
        <Route exact path="/admin/order/:id" element={<OrderUpdate />} />

        <Route exact path="/admin/users" element={<UsersList />} />
        <Route exact path="/admin/user/:id" element={<UpdateUser />} />

        <Route path="*" element={<NotFound />} />
      </Routes>
      {/* <GoToTop /> */}

      <Footer />
    </div>
  );
}

export default AllRoutes
