import React, { createContext, useState } from 'react';

export const AccordionContext = createContext({
    active: 0,
    setActive: () => { }
});

const AccordionWrapper = (props) => {
    const [active, setActive] = useState(0);
    const type = props.className;

    return (
        <AccordionContext.Provider
            value={{
                active, setActive, type
            }}
        >
            <div className={`accordion-wrapper ${props?.className}`}>
                {/* <form> */}
                {props.children}
                {/* </form> */}
            </div>
        </AccordionContext.Provider>
    )
};

export default AccordionWrapper;
