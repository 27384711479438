import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import cartServices from "../services/cart.service";

const initialState = {
    cart: [],
    loading: false,
    error: false
};




export const fetchCartData = createAsyncThunk(
    "cart/fetchCartData",
    async (arg, thunkAPI) => {
        try {
            const response = await cartServices.getCartData();
            return response;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);




export const addToCart = createAsyncThunk(
    "cart/addToCart",
    async (Obj, thunkAPI) => {
        try {
            const response = await cartServices.addToCart(Obj);
            return response;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);


export const updateCart = createAsyncThunk(
    "cart/updateCart",
    async (Obj, thunkAPI) => {
        try {
            const response = await cartServices.updateCart(Obj);
            return response;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const removeCartItem = createAsyncThunk(
    "cart/removeCartItem",
    async (Obj, thunkAPI) => {
        try {
            const response = await cartServices.removeCartItem(Obj);
            return response;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);





export const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        clearCart: (state, action) => {
            state.cart = []
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCartData.pending, (state) => {
                // state.cart = []
                state.loading = true
                state.error = false
            })
            .addCase(fetchCartData.fulfilled, (state, action) => {
                state.cart = action.payload.data.getCartItems
                state.loading = false
            })
            .addCase(fetchCartData.rejected, (state) => {
                state.cart = []
                state.loading = true
                state.error = true
            })
            .addCase(updateCart.fulfilled, (state, action) => {
                console.log("crrr", action.payload.data)
            })
    },
})

export const { clearCart } = cartSlice.actions
export const cartSliceReducer = cartSlice.reducer
