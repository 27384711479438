import HeaderStrip from './HeaderStrip/HeaderStrip';
import HeaderLogo from './HeaderLogo/HeaderLogo';
import HeaderMenu from './HeaderMenu/HeaderMenu';
import SearchBar from "./SearchBar/SearchBar";
import './header.scss';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCartData } from '../../../redux-toolkit/slices/cartSlice';

function Header() {
  const dispatch = useDispatch();
  const { userAuth } = useSelector((state) => state);

  useEffect(() => {
    if (userAuth.isAuthenticated) {
      dispatch(fetchCartData())
    }
  }, [])


  return (
    <header>
      <HeaderStrip />
      <div className="header-container">
        <HeaderLogo />
        <SearchBar />
        <HeaderMenu />
      </div>
    </header>
  );
}
export default Header;
