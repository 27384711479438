import { jwtDecode } from "jwt-decode";
const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY2MTJlNDFlMzA3ZmRkNjBjMDZmN2U3YSIsImlhdCI6MTcxMzQ1NTg5MSwiZXhwIjoxNzEzNzE1MDkxfQ.TCs60hws38l-sIWuyEhn4mYr_DX-qGOGUIMZ44adlZg";


export const isValidJWT = () => {
    const authToken = localStorage.getItem("authToken")
    if (authToken) {
        const decoded = jwtDecode(token);
        let currentDate = new Date();

        // JWT exp is in seconds
        if (decoded.exp * 1000 < currentDate.getTime()) {
            return false
        } else {
            return true;
        }
    }
    return "notavailable"

}
