import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { getOrderDetails } from '../../actions/orderAction';
import { removeCartItem } from '../../redux-toolkit/slices/cartSlice';
import './ConfirmOrder.scss';

function ConfirmOrder() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { orderDetails } = useSelector((state) => state);
  const { order } = orderDetails;

  const param = useParams();
  useEffect(() => {
    dispatch(getOrderDetails(param?.order_id))
  }, [param])

  // useEffect(() => {
  //   if (orderDetails?.order?.orderItems?.length > 0) {
  //     orderDetails?.order?.orderItems?.map((items) => {
  //       dispatch(removeCartItem({ "cartId": items?.product }));
  //     })
  //   }
  // }, [orderDetails])

  const shippingInfo = order?.shippingInfo?.[0] || {}

  const orderItems = order?.orderItems
  console.log(order)
  // console.log(order)
  // console.log(shippingInfo)

  return (
    <React.Fragment>
      <div className='parent-div' style={{ margin: "5% 10%", backgroundColor: "#eef3fc", borderRadius: '20px', padding: "32px 48px 68px 48px" }}>
        <div className='heading-1'>
          <p style={{ fontSize: "24px", fontWeight: 500 }}>Order Details</p>
        </div>
        <div className='parent-2' style={{ display: 'flex', flexDirection: 'row', width: "100%", gap: '48px' }}>
          <div className='left-panel' style={{ width: '60%', display: 'flex', flexDirection: 'column', gap: '24px' }}>
            <div className='address' style={{ backgroundColor: 'white', borderRadius: '20px', padding: '16px 28px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <div className='left-address'>
                <div className='address-bar' style={{
                  display: 'flex',
                  gap: '10px',
                  height: '22px',
                  alignItems: 'center',
                  marginBottom: '15px'
                }}>
                  <span><i className='fas fa-map-marker-alt'></i></span>
                  <p style={{ fontWeight: 500 }}>Address</p>
                </div>
                {true && (
                  <>
                    <div className='name' style={{ color: '#0d4bcd', fontWeight: 500, marginBottom: '12px' }}>
                      {shippingInfo.firstName} {shippingInfo.lastName}
                    </div>
                    <p className='adddress-1 m-0 f-12'>{shippingInfo.address}</p>
                    <p className='adddress-2 m-0 f-12'>{shippingInfo.city}</p>
                    <p className='adddress-3 m-0 f-12'>{shippingInfo.country}</p>
                    <p className='adddress-3 m-0 f-12'>{shippingInfo.pinCode}</p>
                    <p className='adddress-4 m-0 f-12'>Phone :- {shippingInfo.phoneNo}</p>
                    <p className='adddress-4 m-0 f-12'>Email :- {shippingInfo.email}</p>
                    <div className='edit-add' style={{ marginTop: '20px' }}>
                      {/* <button className='c-btn'>Edit Address</button> */}
                    </div></>)
                }
              </div>

              <div className='right-address'>
                {/* <button className='c-btn'>Choose another address</button> */}
              </div>
            </div>
            <div className='cart' style={{ marginTop: '20px' }}>
              <div className='parent-cart'>
                <div className='cart-heading' style={{ display: 'flex', flexDirection: "row", justifyContent: 'space-between', marginBottom: '15px' }}>
                  <div className='cart-title'>
                    <p className='m-0' style={{ fontWeight: 500 }}>Your Order <span style={{ color: '#aaaaaa', fontSize: '12px' }}>
                      ({orderItems && orderItems.length} Items)</span></p>
                  </div>
                  <div className='add-wishlist'>
                    {/* <button className='c-btn'>+ Add More</button> */}
                  </div>
                </div>
                <div style={{ backgroundColor: 'white', borderRadius: '20px' }}>
                  {orderItems &&
                    orderItems.map((order, i) => (
                      <div key={i} className='cart-item' style={{ padding: '16px 28px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <div className='cart-item-parent' style={{ display: 'flex', flexDirection: 'row', gap: '40px' }}>
                          <div className='img-boxx' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} >
                            <img className='img-f' style={{ width: '100px', height: '100px' }} src={order.image} alt='product-img' />

                          </div>
                          <div className='cart-details' style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                            <p className='m-0 f-14' style={{ color: '#0124c3' }}>{order?.name}</p>
                            <p className='m-0 f-14' style={{ color: '#929292' }}>{order?.productCategory?.toUpperCase()} {order?.productSize?.toUpperCase()}</p>
                            <div className='price-container f-14' style={{ display: 'flex', flexDirection: "row", gap: '20px' }}>

                              <p className='m-0 f-14'>₹ {order?.price}</p>
                              <div className='discount' style={{ backgroundColor: "#f5e9d9", borderRadius: '20px', padding: " 3px 8px" }}>
                                <p className='m-0' style={{ color: "#ba6215", fontSize: '12px' }}>50% Off</p>
                              </div>
                            </div>
                            {/* <p className='m-0 f-14' style={{ marginTop: "10px", color: "#31a256" }}><span><i className='fas fa-truck'></i></span> Est. Delivery By {'deliverDate'}</p> */}
                          </div>
                          <div className='cart-size-qty' style={{ textAlign: 'center' }}>
                            <p className='m-0 f-14' style={{ fontWeight: 400 }}>Quantity: {order?.qty}</p>
                            {/* <p className='m-0 f-14'></p> */}
                          </div>
                        </div>
                      </div>
                    ))}
                </div>

              </div>
            </div>
          </div>
          <div className='right-panel' style={{ width: '40%', backgroundColor: 'white', borderRadius: '20px', padding: '16px 28px', display: 'flex', flexDirection: 'column' }}>
            <p className='m-0' style={{ fontWeight: 500, marginBottom: "20px" }}>Order Summary</p>
            <div className='order-summary-1' style={{ display: 'flex', flexDirection: 'column', gap: '10px', borderBottom: '1px solid #e6e6e6', paddingBottom: '15px' }}>
              <div className='bag-total bag-style' >
                <p className='m-0 f-14'>Bag Total</p>
                <p className='m-0 f-14'>₹ {order?.totalPrice}</p>
              </div>
              <div className='bag-discount bag-style'>
                <p className='m-0 f-14'>Bag Discout</p>
                <p className='m-0 f-14'>₹ {order?.taxPrice}</p>
              </div>
              <div className='delivery-fee bag-style'>
                <p className='m-0 f-14'>Delivery Fee</p>
                <p className='m-0 f-14'>₹ {order?.shippingPrice}</p>
              </div>
              <div className='order-total bag-style'>
                <p className='m-0' style={{ color: "#018929", fontWeight: 500 }}>Order Total</p>
                <p className='m-0' style={{ color: "#018929", fontWeight: 500 }}>₹ {order?.totalPrice}</p>
              </div>
            </div>
            {/* <p className='m-0' style={{ fontWeight: 500, marginTop: "20px", marginBottom: '5px' }}>Payment Options</p> */}
            {/* <div className="form-check mb">
              <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" defaultChecked /><span style={{ fontSize: "14px" }}> UPI/ Wallet/ Debit Card/ Credit Card/ Net Banking</span>
            </div> */}
            {/* <div style={{ marginTop: 'auto' }}>
              <button disabled={'isOpen'} style={{ backgroundColor: "#5386e4", color: 'white', textDecoration: 'none', borderRadius: "10px", width: "100%", border: 'none', padding: "15px", cursor: 'pointer' }}>Pay ₹ {'cartTotal'}</button>
            </div> */}
          </div>
        </div>
      </div>
    </React.Fragment>



  )
}

export default ConfirmOrder