import React from 'react'
import AccordionItem from '../../../reusable-component/accordion/AccordionItem'
import { useSelector } from 'react-redux';

function Profile() {
    const { userAuth } = useSelector(state => state)
    const { userLoginData } = userAuth
    return (
        <AccordionItem
            title={'Profile'}
            index={3}
            description={<div className="prflbx">
                <div className="mb-3">
                    <label className="form-label">Full Name</label>
                    <div className="input-field">
                        <i className="fas fa-user"></i>
                        <input
                            className="form-control"
                            value={userLoginData?.name}
                            disabled={true}
                        />
                    </div>
                </div>

                <div className="mb-3">
                    <label className="form-label">Email address</label>
                    <div className="input-field">
                        <i className="fas fa-user"></i>
                        <input
                            type="email"
                            className="form-control"
                            value={userLoginData?.email}
                            disabled={true}
                        />
                    </div>
                </div>
            </div>}>
        </AccordionItem>
    )
}

export default Profile