import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
// import { clearErrors, login } from "../../../actions/userAction";
// import { useAlert } from "react-alert";

import { dirImages } from '../../../Utilites/GetImage'
import SignupComponent from "./SignupComponent";
import SignInComponent from "./SignInComponent";
import './Login.scss';

// import "./LoginSignUp.css"

function LoginComponent() {
  // const dispatch = useDispatch();
  // const alert = useAlert();
  const navigate = useNavigate();

  const { isAuthenticated } = useSelector(
    (state) => state.userAuth
  );

  const loginPanelClass = useRef(null);

  const handleClick = () => {
    loginPanelClass.current.classList.add("sign-up-mode");
  };


  const handleRemoveClass = () => {
    loginPanelClass.current.classList.remove("sign-up-mode");
  };

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/')
    }

  }, [isAuthenticated])



  return (
    <div className="login-container" ref={loginPanelClass}>
      <div className="forms-container">
        <div className="signin-signup">
          <SignInComponent />
          <SignupComponent />
        </div>
      </div>
      <div className="panels-container">
        <div className="panel left-panel">
          <div className="content">
            <h3>Hello, Friend!</h3>
            <p>Enter your personal details and start journey with us</p>
            <button className="btn transparent" id="sign-up-btn" onClick={handleClick}>
              Sign up
            </button>
          </div>
          <img src={dirImages['login.svg']} className="image" alt="" />
        </div>
        <div className="panel right-panel">
          <div className="content">
            <h3>Welcome Back!</h3>
            <p>To keep connected with us please login with your personal info</p>
            <button className="btn transparent" id="sign-in-btn" onClick={handleRemoveClass}>
              Sign in
            </button>
          </div>
          <img src={dirImages['register.svg']} className="image" alt="" />
        </div>
      </div>
    </div>
  );
}

export default LoginComponent;
