import React, { useEffect } from "react";
import Sidebar from "./Sidebar.js";
import "./dashboard.css";
import { Typography } from "@material-ui/core";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import MetaData from "../layout/MetaData";
import { productCount } from "../../redux-toolkit/slices/adminSlice/adminProductSlice.js";

const Dashboard = () => {
  const dispatch = useDispatch();
  const { aProducts, userAuth } = useSelector(state => state)

  const navigate = useNavigate()
  const role = userAuth?.userLoginData?.role
  if (role !== "admin") {
    navigate("/")
  }

  const { totalProducts } = aProducts

  // let outOfStock = 0;
  let totalAmount = 0;

  useEffect(() => {
    dispatch(productCount())
  }, [])



  return (

    <div className="container">
      <div className="dashboard">
        <MetaData title="Dashboard - Admin Panel" />
        <Sidebar />

        <div className="dashboardContainer">
          <Typography component="h1">Dashboard</Typography>

          <div className="dashboardSummary">
            <div>
              <p>
                Total Amount <br /> ₹{totalAmount}
              </p>
            </div>
            <div className="dashboardSummaryBox2">
              <Link to="/admin/products">
                <p>Product</p>
                <p>{totalProducts && totalProducts?.productCount}</p>
              </Link>
              <Link to="/admin/orders">
                <p>Orders</p>
                {/* <p>{orders && orders?.length}</p> */}
              </Link>
              <Link to="/admin/users">
                <p>Users</p>
                {/* <p>{users && users?.length}</p> */}
              </Link>
            </div>
          </div>

          {/* <div className="lineChart">
          <Line data={lineState} />
        </div>

        <div className="doughnutChart">
          <Doughnut data={doughnutState} />
        </div> */}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
