import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { getOrderById, updateOrder } from '../../redux-toolkit/slices/adminSlice/adminOrderSlice';
import Sidebar from './Sidebar';
import Loader from '../layout/Loader/Loader';
import { Link } from 'react-router-dom';
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import { toast } from 'react-toastify';

function OrderUpdate() {
    const params = useParams()
    const dispatch = useDispatch();
    const navigate = useNavigate();
    // const location = useLocation()
    const [status, setStatus] = useState("");

    const { aOrders, userAuth } = useSelector((state) => state);
    const { orderDeatils } = aOrders
    const { order } = orderDeatils

    const role = userAuth?.userLoginData?.role
    if (role !== "admin") {
        navigate("/")
    }

    useEffect(() => {
        dispatch(getOrderById(params.id));
    }, [])


    const updateOrderSubmitHandler = (e) => {
        e.preventDefault();
        dispatch(updateOrder({ status: status, id: params.id }))
            .then(resp => {
                if (resp.payload.data.success === true) {
                    toast.success("Order Updated Successfully")
                } else {
                    toast.error("Something went wrong")
                }
            })
    }

    return (
        <React.Fragment>
            {/* <MetaData title="Process Order" /> */}
            <div className="dashboard">
                <Sidebar />
                <div className="newProductContainer">
                    {false ? (
                        <Loader />
                    ) : orderDeatils?.success === true ? (
                        <div
                            className="confirmOrderPage"
                            style={{
                                display: order?.orderStatus === "Delivered" ? "block" : "grid",
                            }}
                        >
                            <div>
                                <div className="confirmshippingArea">
                                    <h5>Shipping Info</h5>
                                    <div className="orderDetailsContainerBox">
                                        <div>
                                            <p>Name:</p>
                                            <span>{order?.shippingInfo[0]?.firstName} {order?.shippingInfo[0]?.lastName} </span>
                                        </div>
                                        <div>
                                            <p>Phone:</p>
                                            <span>
                                                {order?.shippingInfo[0] && order?.shippingInfo[0]?.phoneNo}
                                            </span>
                                        </div>
                                        <div>
                                            <p>Address:</p>
                                            <span>
                                                {order?.shippingInfo[0] &&
                                                    `${order.shippingInfo[0].address}, ${order.shippingInfo[0].city}, ${order.shippingInfo[0].state}, ${order.shippingInfo[0].pinCode}, ${order.shippingInfo[0].country}`}
                                            </span>
                                        </div>
                                    </div>

                                    <h5>Payment</h5>
                                    <div className="orderDetailsContainerBox">
                                        <div>
                                            <p
                                                className={
                                                    order.payment_status &&
                                                        order.payment_status === "succeeded"
                                                        ? "greenColor"
                                                        : "redColor"
                                                }
                                            >
                                                {order.payment_status &&
                                                    order.payment_status === "succeeded"
                                                    ? "PAID"
                                                    : "NOT PAID"}
                                            </p>
                                        </div>

                                        <div>
                                            <p>Amount:</p>
                                            <span>{order.totalPrice && order.totalPrice}</span>
                                        </div>
                                    </div>

                                    <h5>Order Status</h5>
                                    <div className="orderDetailsContainerBox">
                                        <div>
                                            <p
                                                className={
                                                    order?.orderStatus && order?.orderStatus === "Delivered"
                                                        ? "greenColor"
                                                        : "redColor"
                                                }
                                            >
                                                {order?.orderStatus && order?.orderStatus}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="confirmCartItems">
                                    <h5>Your Cart Items:</h5>
                                    <div className="confirmCartItemsContainer">
                                        {order.orderItems &&
                                            order.orderItems.map((item) => (
                                                <div key={item.product}>
                                                    <img src={item.image} alt="Product" />
                                                    <Link to={`/product/${item.product}`}>
                                                        {item.name}
                                                    </Link>{" "}
                                                    <span>
                                                        {item.qty} X ₹{item.price} ={" "}
                                                        <b>₹{item.price * item.qty}</b>
                                                    </span>
                                                </div>
                                            ))}
                                    </div>
                                </div>
                            </div>
                            <div
                                style={{
                                    display: order.orderStatus === "Delivered" ? "none" : "block",
                                }}
                            >
                                <form
                                    className="updateOrderForm"
                                    onSubmit={updateOrderSubmitHandler}
                                >
                                    <h1>Process Order</h1>

                                    <div>
                                        <AccountTreeIcon />
                                        <select onChange={(e) => setStatus(e.target.value)}>
                                            <option value="">Choose Category</option>
                                            <option value="Processing">Processing</option>
                                            <option value="Shipped">Shipped</option>
                                            <option value="Delivered">Delivered</option>
                                        </select>
                                    </div>

                                    <button
                                        id="createProductBtn"
                                        type="submit"
                                        disabled={
                                            false ? true : false || status === "" ? true : false
                                        }
                                    >
                                        Process
                                    </button>
                                </form>
                            </div>
                        </div>
                    ) : <h6>Order not found</h6>}
                </div>
            </div>
        </React.Fragment>
    )
}

export default OrderUpdate