import React, { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
// import { useAlert } from "react-alert";
import { Button } from "@material-ui/core";
import MetaData from "../layout/MetaData";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import PersonIcon from "@material-ui/icons/Person";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import SideBar from "./Sidebar";
import Loader from "../layout/Loader/Loader";
import { useNavigate, useParams } from "react-router";
import { updateUser, userDetail } from "../../redux-toolkit/slices/adminSlice/adminUserSlice";
import { toast } from "react-toastify";


const UpdateUser = ({ history, match }) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const params = useParams()
  const { aUsers, userAuth } = useSelector((state) => state);
  const userData = aUsers?.userDetails?.user
  const userId = params.id;
  const role = userAuth?.userLoginData?.role
  if (role !== "admin") {
    navigate("/")
  }


  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [roleUser, setRoleUser] = useState("");

  // const { userDetail } = aUsers
  // console.log(userDetail)
  // const {
  //   loading: updateLoading,
  //   error: updateError,
  //   isUpdated,
  // } = useSelector((state) => state.profile);



  useEffect(() => {
    dispatch(userDetail(params.id))
  }, [params])


  useEffect(() => {
    userData?.name && setName(userData?.name);
    userData?.name && setEmail(userData?.email);
    userData?.role && setRoleUser(userData?.role);
  }, [userData])


  const updateUserSubmitHandler = (e) => {
    e.preventDefault();
    const postData = {
      name,
      email,
      role: roleUser
    }
    dispatch(updateUser({ userId, postData })).then(resp => { toast.success("User Data has been updated") }).catch(err => toast.error("Something went wrong"))
  };

  return (
    <Fragment>
      <MetaData title="Update User" />
      <div className="dashboard">
        <SideBar />
        <div className="newProductContainer">
          {false ? (
            <Loader />
          ) : (
            <form
              className="createProductForm"
              onSubmit={updateUserSubmitHandler}
            >
              <h1>Update User</h1>

              <div>
                <PersonIcon />
                <input
                  type="text"
                  placeholder="Name"
                  required
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div>
                <MailOutlineIcon />
                <input
                  type="email"
                  placeholder="Email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>

              <div>
                <VerifiedUserIcon />
                <select value={roleUser} onChange={(e) => setRoleUser(e.target.value)}>
                  <option value="">Choose Role</option>
                  <option value="admin">Admin</option>
                  <option value="user">User</option>
                </select>
              </div>

              <Button
                id="createProductBtn"
                type="submit"
              >
                Update
              </Button>
            </form>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default UpdateUser;
