
import { axiosInstance } from "../../../Utilites/Axios";
import API_URL from "../../../config";


const addNewProduct = (data) => {

    return axiosInstance.post(API_URL.addNewProduct, data, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}


const getAllProducts = () => {
    return axiosInstance.get(API_URL.getAllProducts);
};



const updateProduct = (id, data) => {
    return axiosInstance.put(`${API_URL.updateProduct}/${id}`, data, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}
const deleteProduct = (id) => {
    console.log(`${API_URL.updateProduct}/${id}`)
    return axiosInstance.delete(`${API_URL.updateProduct}/${id}`)
}


const getProductById = (id) => {
    return axiosInstance.get(`${API_URL.GetProductDetails}/${id}`)
}


const productCount = () => {
    return axiosInstance.get(API_URL.productCount)
}




export const adminProductService = {
    addNewProduct,
    getAllProducts,
    updateProduct,
    deleteProduct,
    getProductById,
    productCount
}